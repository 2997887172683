import {Map} from 'immutable'

import {ADD_KEY_FIELD_TOOL_DATA, DELETE_KEY_FIELD_TOOL_DATA, FETCH_KEY_FIELDS_TOOL_DATA} from './actions'

const deleteKeyFieldValues = (state, payload) => {
  const hasOneField = state.get(payload.get('key')).size < 2

  if (hasOneField)
    return state.deleteIn([payload.get('key')])
  else
    return state.deleteIn([payload.get('key'), payload.get('keyFieldOptionNumber').toString()])
}

const reducer = (state = Map({}), action) => {
  switch (action.type) {
    case FETCH_KEY_FIELDS_TOOL_DATA:
      return action.payload
    case ADD_KEY_FIELD_TOOL_DATA:
      return state.mergeDeep(action.payload)
    case DELETE_KEY_FIELD_TOOL_DATA:
      return deleteKeyFieldValues(state, action.payload)
    default:
      return state
  }
}


export default reducer
