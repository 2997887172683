// Run a method only if in the dev environment:
const onlyInDev = callback => {
  if (__DEV__)
    return callback()
}

// Outputting to the console:
const log = input => onlyInDev(() => console.log(input)) // eslint-disable-line no-console

const hr = (character = '*') => onlyInDev(() => log(character.repeat(80)))

const wrappedLog = input => {
  hr()
  log(input)
  hr()
}

const prettyLog = input => wrappedLog(JSON.stringify(input, null, 2))

export default {
  hr,
  log,
  onlyInDev,
  prettyLog,
  wrappedLog
}
