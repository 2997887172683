import {Map} from 'immutable'

import {cleanResponse} from '../redux/apiHelper'
import {elementDictionary} from '../../../../../lib/plan_data/item'

import {RESHAPE_ELEMENTS} from '../actions/corpMaxActionTypes'

const elementsReducer = (state = Map({}), action) => {
  switch (action.type) {
    case RESHAPE_ELEMENTS:
      return elementDictionary(cleanResponse(action.payload))
    default:
      return state
  }
}

export default elementsReducer
