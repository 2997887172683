import React from 'react'
import Label from './Label'
import Errors from './Errors'
import classnames from 'classnames'

const Wrapper = props => {
  var element = React.Children.count(props.children) > 1 ? (<div className='compound-form-element'>{props.children}</div>) : props.children
  return (
    <Label {...props} className={classnames('form-widget', props.className)}>
      <div className='form-element'>
        {element}
        <Errors errors={props.errors} />
      </div>
    </Label>
  )
}

export default Wrapper
