import {OrderedMap, Map, List} from 'immutable'
import {cleanResponse} from '../../dashboard/src/components/corp_max/redux/apiHelper'

// /////////////////////////////////////////////////////////////////////////////
// High-Level Functions

/**
 * Converts two candidates to strings in order to successfully compare numbers with strings successfully.
 * By creating a standalone method here we don't have to constantly test other methods with mixed inputs
 * and thus save time in the long run.
 *
 * @param {number,string} candidateOne - the first number or string to be compared.
 * @param {number,string} candidateTwo - the second number or string to be compared.
 *
 * @returns {boolean} `true` if after conversion to strings the two inputs are equal, `false` otherwise.
 */
export const compareAsStrings = (candidateOne, candidateTwo) => (String(candidateOne) === String(candidateTwo))

/**
 * Finds all resources by the `attribute` and `id` given
 *
 * @param {List} rawResource - a raw API response for a multi-record resource
 * @param {string} attribute - `attribute` to filter resources by
 * @param {number,string} id - the ID of the `attribute` on each record of the resources
 *
 * @returns {List} of the matching resources if any are found, an empty `List` otherwise
 */
export const filterRawResourceByAttributeId = ({attribute, id, rawResource}) => (
  cleanResponse(rawResource).filter(resource => compareAsStrings(resource.get(attribute), id)) || List()
)

/**
 * Finds all resources that with a matching attribute value
 *
 * @param {List} attribute - the attribute we're checking for
 * @param {List} rawResource - a raw API response for a multi-record resource
 * @param {number,string} value - the value of the `attribute` on each record of the resources
 *
 * @returns {List} of the matching resources if any are found, an empty `List` otherwise
 */
export const findRawResourceByAttributeValue = ({attribute, value, rawResource}) => (
  cleanResponse(rawResource).find(resource => compareAsStrings(resource.get(attribute), value)) || Map()
)

/**
 * Finds all resources that with a matching `everplan-id` attribute
 *
 * @param {List} rawResource - a raw API response for a multi-record resource
 * @param {number,string} id - the ID of the `attribute` on each record of the resources
 *
 * @returns {List} of the matching resources if any are found, an empty `List` otherwise
 */
export const filterRawResourceByEverplanId = ({id, rawResource}) => filterRawResourceByAttributeId({
  attribute: 'everplan-id',
  id,
  rawResource
})


/**
 * Finds a resource by the attribute and id given
 *
 * @param {array} rawResource a raw API response
 *  @param {string} attribute to find by, must be a number and not a UUID
 * @param {number} id of the attribute on the resource
 *
 * @returns {Map} of the resource if found
 */

export const findRawResourceByAttributeId = ({rawResource, attribute, id}) => (
  cleanResponse(rawResource, Map()).find(resource => compareAsStrings(resource.get(attribute), id)) || Map()
)

/**
 * Finds a resource by the id given
 *
 * @param {array} rawResource a raw API response for a multi-record resource
 * @param {number} id of the attribute on the resource
 *
 * @returns {Map} of the resource if found
 */

export const findRawResourceById = ({rawResource, id}) => findRawResourceByAttributeId({attribute: 'id', id, rawResource})

/**
 * @param {List} resource
 * @param {number,string} id - the ID that should be checked against
 *
 * @returns {List} of the resources whose ids do not match the given id if any are found, othwerwise return an empty `List`
 */
export const filterNotResourceById = ({resource, id}) => (
  resource.filterNot(resourceData => compareAsStrings(resourceData.get('id'), id)) || List()
)

/**
 * @param {List} - to be re-ordered
 * @param {number} sourceIndex
 * @param {number} targetIndex
 *
 * @returns {List} that is re-ordered based on the source and target index
 */
export const reorderListByIndex = ({list, sourceIndex, targetIndex}) => {
  const movingItem = list.get(sourceIndex)

  return list
    .remove(sourceIndex, 1)
    .insert(targetIndex, movingItem)
}


// /////////////////////////////////////////////////////////////////////////////

/*
 * some util functions for traversing the list of user data
 * we are assuming the default shape of the user data is an immutable list of of objects that look like:
 * {elementId: bla, groupId: bla, id: bla, value: bla}
 */

export const allByElementId = (list, elementId) => list.filter(item => item.get('elementId') === elementId)
export const byElementId = (list, elementId) => allByElementId(list, elementId).first()
export const asMap = list => list.reduce((dictionary, item) => dictionary.set(item.get('id'), item), Map())


/**
 * Find the eveplan-response associated with given everplanId, which includes information about the everplan and its responses
 *
 * @param {Object} everplan-responses directly from state.api
 * @param {string} everplanId
 *
 * @returns {Map} everplan-response that contains responses, if its a household everplan, and migration date information as an immutable
 */
export const findEverplanResponseByEverplanId = ({everplanResponses = {data: {}}, everplanId}) => (
  // Convert to string, because sometimes the everplanId given is not in string form
  cleanResponse(everplanResponses).find(everplanResponse => everplanResponse.get('id') === String(everplanId)) || Map()
)

/**
 * @param {Object} firmOwnerships directly from state.api
 * @param {Object} firmConfigs directly from state.api
 * @param {Map} userConfig
 *
 * @returns {Map} firmConfig for a client if user is a client
 */
export const findFirmConfigByFirmOwnership = ({firmOwnerships, firmConfigs, userConfig}) => {
  if (userConfig.get('client')) {
    const firmOwnership = findRawResourceByAttributeId({rawResource: firmOwnerships, attribute: 'everplan-id', id: userConfig.get('everplan-id')})
    return findRawResourceByAttributeId({rawResource: firmConfigs, attribute: 'firm-id', id: firmOwnership.get('ownable-id')})
  } else {
    return Map()
  }
}

/**
 * Finds all new-contacts associated with given everplanId
 *
 * @param {Object} new-contacts directly from state.api
 * @param {string} everplanId
 *
 * @returns {List} newContacts or empty list if none found
 */
export const filterContactsByEverplanId = ({newContacts, everplanId}) => (
  filterRawResourceByEverplanId({id: everplanId, rawResource: newContacts})
)

/**
 * Finds a resource by id given
 *
 * @param {List} resourceList
 * @param {number} id of the resource needed
 *
 * @returns {Map} of the resource if found
 */
export const findResourceById = ({resourceList, id}) => (
  resourceList.find(resource => compareAsStrings(resource.get('id'), id)) || Map()
)

export const filterResourceByAttributeId = ({attribute, id, resourceList}) => (
  resourceList.filter(resource => compareAsStrings(resource.get(attribute), id))
)

/**
 * Finds a resource by the attribute and id given
 *
 * @param {List} resourceList
 *  @param {string} attribute to find by, must be a number and not a UUID
 * @param {number} id of the attribute on the resource
 *
 * @returns {Map} of the resource if found
 */
export const findResourceByAttributeId = ({resourceList, attribute, id}) => (
  resourceList.find(resource => compareAsStrings(resource.get(attribute), id)) || Map()
)


/**
 * @param {number} everplanPreviewId of everplan being viewed
 * @param {List} owners from state.owners, it always exists even if it is a deputy only user
 *
 * @returns {boolean} depending on if the everplan being viewed is the current user's or not
 */
export const isOwnEverplan = ({everplanPreviewId, owners}) => parseInt(everplanPreviewId, 10) === owners.getIn([0, 'everplan-id'])


/**
 * Finds all secondaryEverplanOwners associated with the everplan being viewed
 *
 * @param {Object} secondaryEverplanOwners directly from state.api
 * @param {number} everplanPreviewId of the everplan being viewed
 * @param {List} owners from state.owners, it always exists even if it is a deputy only user
 *
 * @returns {List} ownerships for the everplan being viewed as an immutable object
 */
export const everplanOwnerships = ({secondaryEverplanOwners = {data: []}, everplanPreviewId, owners}) => {
  const ownerships = isOwnEverplan({everplanPreviewId, owners}) ?
    owners :
    findResourceById({resourceList: cleanResponse(secondaryEverplanOwners), id: everplanPreviewId}).get('owners')
  return ownerships || List()
}

/**
 * Gets everplan owners for either the current user or as a deputy when using Transformer data.
 *
 * @param {Map} ownership if someone elses everplan
 * @param {boolean} ownEverplan
 * @param {List} owners from state.owners for the current user
 *
 * @returns {List} owners
 */
export const everplanOwners = ({ownership, ownEverplan, owners}) => (
  ownEverplan ? owners : ownership.getIn(['everplan-owner', 'owners'])
)


/**
 * Finds the deputy-ownership associated with the current user for the plan being viewed
 *
 * @param {Object} deputyOwnerships directly from state.api
 * @param {Map} userConfig
 * @param {String} the everplanId from the params
 *
 * @returns {Map} deputyOwnership for a user is a deputy for as an immutable Map
 */
export const ownershipByDeputy = ({ownerships, userConfig, everplanId}) => (
  ownerships.find(ownership => ownership.get('ownable-id') === parseInt(userConfig.get('id'), 10) &&
  ownership.get('everplan-id') === parseInt(everplanId, 10))
)


/**
 * Finds all item-permissions associated with a deputyOwnership
 *
 * @param {Map} deputyOwnership for the current user
 *
 * @returns {List} item-permissions for a deputy ownership grouped by permission type
 */
export const deputyPermissions = deputyOwnership => {
  if (deputyOwnership && !deputyOwnership.isEmpty())
    return deputyOwnership.get('item-permissions').groupBy(permission => permission.get('permission'))

  return OrderedMap()
}

/**
 * Finds accepted deputy-ownerships associated with the user, which are basically all user deputy-ownerships that are not associated with
 * the current user's everplan.
 *
 * @param {Object} deputyOwnerships directly from state.api
 * @param {Number} everplanId
 *
 * @returns {List} deputyOwnerships a user is a deputy for as an immutable List
 */
export const filterAcceptedDeputyForOwnerships = ({deputyOwnerships, everplanId}) => (
  cleanResponse(deputyOwnerships)
    .filter(deputyOwnership => (
      deputyOwnership.get('everplan-id') !== everplanId &&
      deputyOwnership.get('ownable-type') === 'User'
    ))
)

/**
 * Finds pending deputy-ownerships associated with the user, which are basically all invite deputy-ownerships that are not associated with
 * the current user's everplan.
 *
 * @param {Object} deputyOwnerships directly from state.api
 * @param {Number} everplanId
 *
 * @returns {List} pending deputyOwnerships for a user as an immutable List
 */
export const filterPendingDeputyForOwnerships = ({deputyOwnerships, everplanId, deputyInvites}) => {
  if (!deputyOwnerships || !deputyInvites)
    return List()

  const reducer = (collection, record) => collection.set(parseInt(record.get('id'), 10), record)
  const deputyInviteDictionary = cleanResponse(deputyInvites).reduce(reducer, Map())

  return cleanResponse(deputyOwnerships)
    .filter(deputyOwnership => (
      deputyOwnership.get('everplan-id') !== everplanId &&
      deputyOwnership.get('ownable-type') === 'Invite' &&
      deputyInviteDictionary.getIn([deputyOwnership.get('ownable-id'), 'status']) !== 'declined'
    ))
}

/**
 * Finds all ownerships associated with an everplanId
 *
 * @param {Object} ownerships directly from state.api
 * @param {number} everplanId
 *
 * @returns {List} ownerships
 */
export const filterOwnershipByEverplanId = ({ownerships, everplanId}) => (cleanResponse(ownerships).filter(ownership => ownership.get('everplan-id') === parseInt(everplanId, 10)) || List())

/**
 * Filters the data in the form and previous response by user
 *
 * @param {List} formdata
 *
 * @returns {List} responses
 */
export const filteredResponse = response => response.filter(datum => datum.get('value') && datum.get('value') !== '')

/**
 * Compares the formdata and user response and checks for any update
 *
 * @param {List} initialFormData the user's initial response
 * @param {List} newFormData data in the form
 *
 * @returns {boolean}
 */
export const anyDataUpdated = (initialFormData, newFormData) => {
  const oldResponse = filteredResponse(initialFormData).map(data => data.get('value')).sort()
  const newResponse = filteredResponse(newFormData).map(data => data.get('value')).sort()

  /** handles a scenario when the newResponse is selected and then later unselected
   * for a radio or checkbox, the newResponse is would have an empty List or Map
   * therefore would no longer be equal to oldResponse. -- SIMI
  */
  if (newResponse.size === 1 && (Map.isMap(newResponse.first()) || List.isList(newResponse.first())))
    return !newResponse.first().isEmpty()
  else
    return !oldResponse.equals(newResponse)
}
