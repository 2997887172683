import PropTypes from 'prop-types'


const CloseIcon = ({fill, ...otherProps}) => (
  <svg {...otherProps} viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path fill={fill} d='M30.949 27.051c1.056 1.056 1.056 2.773 0 3.835-0.528 0.528-1.221 0.795-1.915 0.795s-1.387-0.267-1.915-0.795l-11.035-11.035-11.035 11.029c-0.528 0.528-1.221 0.795-1.915 0.795s-1.387-0.267-1.915-0.795c-1.061-1.056-1.061-2.773 0-3.829l11.029-11.029-11.029-11.035c-1.061-1.056-1.061-2.773 0-3.835 1.056-1.056 2.773-1.056 3.835 0l11.029 11.029 11.029-11.029c1.056-1.056 2.773-1.056 3.835 0 1.056 1.056 1.056 2.773 0 3.835l-11.035 11.029 11.035 11.035z' />
    </g>
  </svg>
)

CloseIcon.defaultProps = {
  fill: '#515151',
  height: '32',
  width: '32'
}

CloseIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

export default CloseIcon
