/* eslint-disable no-underscore-dangle */
import AppDispatcher from '../../../lib/ep-dispatcher'
import ConfigActions from '../../../shared_actions/ConfigActions'
import SessionApiResponse from './SessionApiResponse'
import SignInActions from '../actions/SignInActions'
import jsonStatham from '../../../lib/jsonStatham'
import extra_storage from '../../../lib/extra_storage'
import routerUtils from '../../../lib/routerUtils'
import {railsUrl} from '../../../lib/urlTools'
import {generateSessionId} from '../../../lib/tools'
import {initializeAxiosConfiguration} from '../../../lib/axiosUtils'
import {store} from '../../../lib/epRouter'

let sessionPing = null
let signedIn = false
const sessionId = generateSessionId()

const SessionApi = {
  initSession(withKeepAlive) { this.fetchCurrentUser(withKeepAlive) },
  signOutUser() {
    jsonStatham.delete('/auth/auth', null)
      .done((data, status) => SessionApiResponse.userSignedOut())
      .always(() => { this._clearSession() })
  },
  signInUser(email, password) {
    jsonStatham.post('auth/auth', {
      user: {
        email: email.toLowerCase(),
        password,
        trust_me_token: extra_storage.getItem('ep_2fdt'),
        session_id: sessionId
      }
    })
      .fail(response => { SessionApiResponse.userSignInRejected(response.errors[0]) })
      .done((data, status) => {
        this.updateTfaToken(data, status)

        if (status === 202) {
          if (data.info === 'Confirmation required')
            routerUtils.setLocation(data.redirect_url)
          else
            SessionApiResponse.tfaCodeSent(data.primary_number, data.secondary_number, 'primary', data.last_enabled_at)
        } else {
          this._setToken(data.session_token)
          this._startSession()
          SessionApiResponse.userSignedIn(data)
        }
      })
  },
  signInUserWithCode(data) {
    const userData = {
      email: data.email,
      password: data.password,
      otp: data.code,
      phone_id: data.phoneId,
      trust_me_token: extra_storage.getItem('ep_2fdt'), // 2fdt: two-factor device token.
      session_id: sessionId
    }
    if (data.trustMe)
      userData['trust_me'] = true

    jsonStatham.post('auth/auth', {user: userData})
      .fail(response => SessionApiResponse.userSignInRejected(response.errors[0]))
      .done((response, status) => {
        this.updateTfaToken(response, status)

        this._setToken(response.session_token)
        this._startSession()
        SessionApiResponse.userSignedIn(response)
      })
  },
  signInSecondary(email, password) {
    jsonStatham.post('auth/auth', {
      user: {
        email,
        password,
        secondary: true,
        session_id: sessionId
      }
    }).fail(response => { SessionApiResponse.userSignInRejected(response.errors[0]) })
      .done((data, status) => {
        if (status === 202) {
          SessionApiResponse.tfaCodeSent(data.primary_number, data.secondary_number, 'secondary', data.last_enabled_at)
        } else {
          this._setToken(data.session_token)
          this._startSession()
          SessionApiResponse.userSignedIn(data)
        }
      })
  },
  submitBackupCode(email, password, backupCode) {
    jsonStatham.post('auth/auth', {
      user: {
        email,
        password,
        backup_code: backupCode
      }
    }).fail(response => { SessionApiResponse.userSignInRejected(response.errors[0]) })
      .done((data, status) => {
        this._setToken(data.session_token)
        SessionApiResponse.userSignedIn(data)
      })
  },
  fetchCurrentUser(withKeepAlive) {
    if (!extra_storage.getItem('authToken')) {
      // don't do anything if there isnt' even an auth token
      this._clearSession()
      return SessionApiResponse.noCurrentUser()
    }
    jsonStatham.get(`auth/sessions?ts=${new Date().getTime()}`)
      .fail(response => {
        this._clearSession()
        SessionApiResponse.noCurrentUser()
      })
      .done((data, status) => {
        if (!sessionPing)
          this._startSession(withKeepAlive)

        SessionApiResponse.currentUserReceived(data)
      })
  },
  _startSession(withKeepAlive) {
    signedIn = true
    // EEK -- this could get slippery, but basically the side effect of the non-truty is that it should defaulit to 'true'
    if (!__TEST__ && withKeepAlive !== false)
      sessionPing = setInterval(this.keepAlive.bind(this), 30000)

    if (!__TEST__)
      ConfigActions.fetchConfig()
  },
  _clearSession() {
    this._removeToken()
    if (!__TEST__)
      clearInterval(sessionPing)
    signedIn = false
  },
  _setToken(token) {
    extra_storage.setItem('authToken', token)
    initializeAxiosConfiguration({store, authToken: token}) // set authToken to redux store so that *redux-json-api* has access to the latest token at all time
  },
  _removeToken(token) { extra_storage.removeItem('authToken') },
  retryCount: 0,
  keepAlive() {
    jsonStatham.get(`/check-in?ts=${new Date().getTime()}`)
      .done(response => {
        this.retryCount = 0
      })
      .fail((response, status) => {
        this.retryCount++

        if (status === 401 || this.retryCount > 2) {
          clearInterval(sessionPing)
          signedIn = false
          routerUtils.setLocation(railsUrl('sign_out'))
        }
      })
  },
  updateTfaToken(data, status) {
    if (status === 202 && data.info === 'Two Factor Authentication required')
      extra_storage.removeItem('ep_2fdt')

    if (status === 200 && data.tfa_config.token)
      extra_storage.setItem('ep_2fdt', data.tfa_config.token)
  }
}

SessionApi.setTokenWithActionCallbackData = data => SessionApi._setToken(data.session_token)

export default SessionApi

AppDispatcher.register(action => {
  switch (action.actionType) {
    case SignInActions.Types.SIGN_IN:
      SessionApi.signInUser(action.email, action.password)
      break
    case SignInActions.Types.SIGN_OUT:
      SessionApi.signOutUser()
      break
    case SignInActions.Types.SIGN_IN_WITH_CODE:
      SessionApi.signInUserWithCode(action.data)
      break
    case SignInActions.Types.SIGN_IN_SECONDARY:
      SessionApi.signInSecondary(action.email, action.password)
      break
    case SignInActions.Types.SUBMIT_BACKUP_CODE:
      SessionApi.submitBackupCode(action.email, action.password, action.backupCode)
      break
    default:
      break
  }
})
