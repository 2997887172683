import cookies from 'browser-cookies'

import routerUtils from './routerUtils'
import * as tools from './sessionTools'

const testFlag = '__extra_storage_flag__'
const testData = 'test'

const extra_storage = {
  getItem(key) {
    if (this.hasLocalStorage())
      return window.localStorage.getItem(key)
    if (this.hasCookies())
      return cookies.get(key)
  },
  setItem(key, value) {
    if (this.hasLocalStorage())
      return window.localStorage.setItem(key, value)
    if (this.hasCookies())
      return cookies.set(key, value)
  },
  removeItem(key) {
    if (this.hasLocalStorage())
      return window.localStorage.removeItem(key)
    if (this.hasCookies())
      return cookies.erase(key)
  },
  hasLocalStorage() {
    try {
      if (!window.localStorage)
        return false

      window.localStorage.setItem(testFlag, testData)
      return window.localStorage.getItem(testFlag) === testData
    } catch (error) {
      return false
    }
  },
  hasCookies() {
    try {
      if (window.navigator.cookieEnabled)
        return true

      cookies.set(testFlag, testData)
      return cookies.get(testFlag) === testData
    } catch (error) {
      this.fireExtraStorageRollbar(error, 'cookies', 'Failed to use both localStorage and cookies.')
      return false
    }
  },
  fireExtraStorageRollbar(error, name, message) {
    tools.fireRollbarError({
      name: `${error.name ? `${error.name} || ` : ''}${name} error`,
      message: `${error.message ? `${error.message} || ` : ''}${message}`,
      code: error.code || 'in extra_storage.js'
    })
  },
  redirectIfNoCookies() {
    if (!this.hasLocalStorage() && !this.hasCookies())
      routerUtils.setLocation('https://www.everplans.com/please-enable-cookies-in-your-web-browser')
  }
}

export default extra_storage

if (typeof window !== 'undefined') window.extra_storage = extra_storage
