import {List, Map} from 'immutable'

/** @module lib/plan_data/category
 *
 * @desc
 * These are helper for munging category data
 */

/** find the items for a specific categoey */
export const categoryItems = (category, items) => (
  items.filter(item => item.get('category-id') === category.get('id'))
)

export const itemsPerCategory = (categories, items) => {
  const reducer = (collection, category) => {
    const categoryWithItems = categoryItems(category, items)
    if (categoryWithItems.size)
      return collection.push(Map({id: category.get('id'), name: category.get('name'), items: categoryWithItems.sortBy(item => item.get('sort-order'))}))

    return collection
  }

  return categories
    .sortBy(category => category.get('sort-order'))
    .reduce(reducer, List())
}

/** find the category for a specific item */
export const categoryByItem = ({categories, item}) => categories.find(category => category.get('id') === item.get('category-id'))

/** construct the category slug */
export const categorySlug = category => category.get('name').toLowerCase().replace(/\s/g, '-')

/** construct the category slug from the given item */
export const categorySlugByItem = ({item, categories}) => categorySlug(categoryByItem({categories, item}))

