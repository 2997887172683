import {List} from 'immutable'

import {cleanResponse} from '../../../../../lib/apiHelpers'

import {RESHAPE_OWNERS} from '../actions/corpMaxActionTypes'

const ownersReducer = (state = List(), action) => {
  switch (action.type) {
    case RESHAPE_OWNERS:
      return cleanResponse(action.payload)
    default:
      return state
  }
}

export default ownersReducer
