import InputBase from './InputBase'
import Wrapper from './Wrapper'

export default class TextArea extends InputBase {
  constructor(props) {
    super(props)
    this.setInitialState(props)
    this.onFormElementChange = this.onFormElementChange.bind(this)
  }
  render() {
    if (this.props.wrap) {
      return (
        <Wrapper {...this.props} errors={this.errors()}>
          <InputElement {...this.props} onChange={this.onFormElementChange} />
        </Wrapper>
      )
    } else {
      return (<InputElement {...this.props} onChange={this.onFormElementChange} />)
    }
  }
}

const InputElement = props => <textarea {...props} autoFocus={props.autoFocus} className='text-area' />

TextArea.defaultProps = {autoFocus: false, wrap: true}
