import validate from 'validate.js'
import moment from 'moment'
import {isValidDate} from './tools'

// taken from validate.js docs -- BJK
validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse(value) { return +moment.utc(value) },
  // Input is a unix timestamp
  format(value, options) {
    var format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss'
    return moment.utc(value).format(format)
  }
})

// `presence: true` doesn't handle all situations our forms present. This acts as a backup.
validate.validators.datetimePresenceBackup = (value, options, key) => {
  if (value === null)
    return `^Please enter a date${key === 'dob' || key === 'birthdate' ? ' of birth.' : ''}`

  if (!isValidDate(value) || value === 'Invalid Date')
    return '^Please enter a valid date.'

  if (moment.utc().subtract(13, 'years') < value)
    return '^You must be at least 13 years old to use Everplans.'

  return ''
}

export default validate
