import PropTypes from 'prop-types'

import fileWhitelist from '../../lib/file_whitelist'
import InputBase from './InputBase'
import Validators from './Validators'


export default class ImageUpload extends InputBase {
  constructor(props) {
    super(props)

    this.setInitialState({
      ...props,
      errors: ''
    })

    // Ensure that the `defaultValue` is set as the current `value` here so that the current image
    // correctly shows in the preview. This is a bit weird, but it's a workaround to avoid changing
    // core functionality in the `setInitialState` method used by all legacy `input`s. --BLR
    this.state.formValue = props.defaultValue

    this.handleImage = this.handleImage.bind(this)
    this.onFormElementChange = this.onFormElementChange.bind(this)
    this.removeImage = this.removeImage.bind(this)
  }

  formValue() { return this.state.formValue }

  handleImage(event) {
    if (event)
      event.preventDefault()

    var reader = new FileReader()
    var ext = event.target.files[0].name.split('.').pop().toLowerCase()
    if (fileWhitelist.includes(ext)) {
      reader.readAsDataURL(event.target.files[0])
      reader.onload = upload => {
        const result = upload.target.result
        this.setState({formValue: result, errors: ''})
        this.onFormElementChange({target: {value: result}})
      }
    } else {
      this.setState({errors: 'Invalid file format.'})
    }
  }

  removeImage(event) {
    if (event)
      event.preventDefault()

    this.onFormElementChange({target: {value: ''}})
    this.setState({formValue: ''})
    $('input.image-upload-input')[0].value = ''
  }

  render() {
    const currentUrl = this.state.formValue

    return (
      <div className='image-upload'>
        <CurrentImage placeholderImage={this.props.placeholderImage} url={currentUrl} />
        <div className='main-controls'>
          <div className='change-photo'>{currentUrl ? 'Change photo' : 'Upload photo'}</div>
          <input className='image-upload-input' onChange={this.handleImage} type='file' />
        </div>
        <div className='remove-photo' onClick={this.removeImage}>{currentUrl ? 'Remove photo' : ''}</div>
        <div className='form-errors'>{this.state.errors}</div>
      </div>
    )
  }
}

ImageUpload.defaultProps = {
  defaultValue: null,
  validator(name, data) {
    var constraints = {}
    constraints[name] = {presence: true}
    return Validators.template(name, data, constraints)
  }
}

const CurrentImage = ({placeholderImage, url}) => {
  if (url)
    return (<img alt='Uploaded image' className='current-image' src={url} />)
  else
    return (<img alt='Placeholder image' className='current-image' src={placeholderImage} />)
}

CurrentImage.propTypes = {
  placeholderImage: PropTypes.string,
  url: PropTypes.string
}
