import invariant from 'invariant'
import jsonify from 'superagent-jsonapify'
import {Map} from 'immutable'
import superagent from 'superagent'

import {mergeConfigs} from './apiHelpers'
import {noop} from './tools'

jsonify(superagent)

const JSON_BOURNE_BASE_CONFIGURATION = {
  headers: Map(),
  method: 'get',
  onFail: noop,
  onSuccess: noop
}

const jsonBourne = (config = Map()) => {
  const configuration = mergeConfigs(JSON_BOURNE_BASE_CONFIGURATION, config)

  // Throw an Invariant Violation if the URL is missing
  invariant(configuration.get('url'), 'API v2: URL is undefined.')

  const request = superagent[configuration.get('method')](configuration.get('url'))

  // Loop over headers and set each one on the request:
  configuration.get('headers').keySeq().forEach(header => request.set(header, configuration.getIn(['headers', header])))

  if (configuration.has('body'))
    request.send(configuration.get('body'))

  request
    .then(response => configuration.get('onSuccess')(response))
    .catch(response => configuration.get('onFail')(response))
}

export default jsonBourne
