const fileWhitelist = [
  '1pif',
  'aif',
  'avi',
  'bmp',
  'csv',
  'doc',
  'docx',
  'eps',
  'flv',
  'gif',
  'heic',
  'html',
  'iff',
  'jpeg',
  'jpg',
  'kdb',
  'kdbs',
  'key',
  'm3u',
  'm4a',
  'mov',
  'mp3',
  'mp4',
  'mpa',
  'mpg',
  'numbers',
  'pages',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'ps',
  'qba',
  'qbb',
  'qbm',
  'qbw',
  'qdf',
  'rm',
  'rtf',
  'sdf',
  'svg',
  'swf',
  'tif',
  'txt',
  'wav',
  'wma',
  'xls',
  'xlsx',
  'xml'
]

export default fileWhitelist
