import {List, Map} from 'immutable'

const Kit = Map({
  name: null,
  elements: List()
})

const Element = Map({
  id: null,
  name: null,
  type: null,
  ordinality: null
})

const PRIMITIVES = List([
  {key: 'TEXT', value: 'Text'},
  {key: 'TEXT-MULTI', value: 'Text-multi'},
  {key: 'DATE', value: 'Date'},
  {key: 'CURRENCY', value: 'Currency'},
  {key: 'NUMBER', value: 'Number'},
  {key: 'FILE', value: 'File - encrypted'},
  {key: 'IMAGE', value: 'File - image'},
  {key: 'PASSWORD', value: 'Password'},
  {key: 'STATE', value: 'State/Province List'},
  {key: 'COUNTRY', value: 'Country List'},
  {key: 'CONTACT', value: 'Contact'},
  {key: 'ADDRESS', value: 'Address'}
])

const ORDINALITY = [
  {key: 'SELECT', value: 'Select'},
  {key: 'NONE', value: 'None'},
  {key: 'SELECT-OTHER', value: 'Select with other'}
]

export {Kit, Element, PRIMITIVES, ORDINALITY}
