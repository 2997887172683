import {hasWhiteLabelCobranding} from '../lib/cobrandingTools'

function hexToRGB(hex) {
  var hexValuesArray = hex.replace('#', '').split('')
  if (hexValuesArray.length === 3)
    hexValuesArray = $.map(hexValuesArray, value => [value, value])
  const hexString = `0x${hexValuesArray.join('')}`
  return [(hexString >> 16) & 255, (hexString >> 8) & 255, hexString & 255].join(', ')
}

const StylishHatAndTails = props => {
  var dangerousStyleTag = () => {
    if (hasWhiteLabelCobranding(props.cobranding)) {
      const rgbForegroundString = hexToRGB(props.cobranding.foreground_color)
      return {__html: `
        .hat-and-tails .menu-item a {
          color: ${props.cobranding.foreground_color};
          padding:  5px 0;
          border-bottom: 2px solid ${props.cobranding.background_color};
        }

        .hat-and-tails .menu-item a:hover {
          text-decoration: none;
          border-bottom: 2px solid ${props.cobranding.foreground_color};
        }

        .hat-and-tails .cobranded {
          color: ${props.cobranding.foreground_color};
          background: ${props.cobranding.background_color};
        }

        .hat-and-tails .fa-white {
          color: ${props.cobranding.foreground_color};
          background: ${props.cobranding.background_color}
        }

        .hat-and-tails .powered-by-divider {
          border-left: 1px solid ${props.cobranding.foreground_color};
        }

        .hat-and-tails .menu-container {
          background: ${props.cobranding.background_color};
        }

        .hat-and-tails .cobranded-divider {
          border-top: 1px solid ${props.cobranding.foreground_color};
          width: 100%;
          opacity: .1;
        }

        .hat-and-tails .quick-plan-menu a.exit-button {
          background: rgba(${rgbForegroundString}, 0.10);
          border: 1px solid rgba(${rgbForegroundString}, 0.50);
          color: ${props.cobranding.foreground_color};
        }

        .hat-and-tails .quick-plan-menu a.exit-button:hover {
          border-bottom: 1px solid rgba(${rgbForegroundString}, 0.50);
        }

        .footer-one {
          box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.12);
        }

        .wrapper {
          min-height: 100%;
          margin: 0 auto -227px;
        }

        .push {
          height: 227px;
        }
        `
      }
    } else if (!props.cobranding.enabled) {
      return {__html: `
        .wrapper {
          min-height: 100%;
          margin: 0 auto -100px;
        }
        .push {
          height: 100px;
        }
        `
      }
    }
  }
  return <style dangerouslySetInnerHTML={dangerousStyleTag()} />
}

export default StylishHatAndTails
