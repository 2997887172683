import PropTypes from 'prop-types'


const PlusIcon = ({fill, ...otherProps}) => (
  <svg {...otherProps} className='plus-icon' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <path fill={fill} d='M30,15.2830189 C30,16.6896226 28.859434,17.8301887 27.4528302,17.8301887 L17.2641509,17.8301887 L17.2641509,27.4528302 C17.2641509,28.859434 16.1235849,30 14.7169811,30 C13.3103774,30 12.1698113,28.859434 12.1698113,27.4528302 L12.1698113,17.8301887 L2.54716981,17.8301887 C1.14056604,17.8301887 0,16.6896226 0,15.2830189 C0,13.8764151 1.14056604,12.7358491 2.54716981,12.7358491 L12.1698113,12.7358491 L12.1698113,2.54716981 C12.1698113,1.14056604 13.3103774,0 14.7169811,0 C16.1235849,0 17.2641509,1.14056604 17.2641509,2.54716981 L17.2641509,12.7358491 L27.4528302,12.7358491 C28.859434,12.7358491 30,13.8764151 30,15.2830189' />
    </g>
  </svg>
)

PlusIcon.defaultProps = {
  fill: '#FFFFFF',
  height: '12',
  width: '12'
}

PlusIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}


export default PlusIcon
