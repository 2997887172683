import Rollbar from 'rollbar'

import SessionStore from '../auth/src/stores/SessionStore'

export const fireRollbarError = (...args) => {
  // Bail if environment is not internal (while we evaluate the data we're passing around) or if we're running tests (BLR):
  if (!INTERNAL_ONLY || __TEST__ || WEBAPPS_HOST.includes('local'))
    return

  const currentUser = SessionStore.initialized() ? SessionStore.getState().currentUser : null
  new Rollbar({
    accessToken: ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    scrubTelemetryInputs: true, // scrubs all inputs values from the telemetry so they are not exposed. setting scrubFields will override this
    payload: {
      client: {
        javascript: {
          code_version: ROLLBAR_CODE_VERSION
        }
      },
      environment: WEBAPPS_HOST.replace(/https?:\/\/webapps\.(.*)\.com/, '$1'),
      person: currentUser ? {email: currentUser.email, id: currentUser.id} : {}
    },
    verbose: ROLLBAR_VERBOSE_MODE
  }).error(...args)
}
// Defensive approach that doesn't assume store has any state. Mostly done so a whole bunch of tests
// of components that use Links based on this helper don't have to set up SessionStore to pass. --BLR
export const wrapperEnabled = () => !!SessionStore.getState().currentUser

