import AppDispatcher from '../lib/ep-dispatcher'
import jsonStatham from '../lib/jsonStatham'
import KeyMirror from 'keymirror'
import * as tools from '../lib/sessionTools'

var ActionPrototype = {
  specialTypes: KeyMirror({GOT_MSG: null}),
  originalActions: {},
  stubForTests() {
    this.receivedData = {}
    let actions = Object.getOwnPropertyNames(this).filter(fn => typeof this[fn] === 'function')
    actions.forEach(action => {
      if (['fireApi', 'stubForTests', 'stubbedAction', 'saveAction', 'restore'].indexOf(action) < 0) {
        this.saveAction(action)

        // TODO: Fix this stubbing so it passes all arguments, not just the first. This can be accomplished by
        // using (...data) instead of just data as the parameter for the stubbed action, but this breaks a bunch of
        // tests that expect only the first argument
        this[action] = data => this.stubbedAction(action, data)
      }
    })
  },
  stubbedAction(actionName, data) { this.receivedData[actionName] = data },
  saveAction(action) { this.originalActions[action] = this[action] },
  restore() {
    let orignals = Object.getOwnPropertyNames(this.originalActions).filter(fn => typeof this.originalActions[fn] === 'function')
    orignals.forEach(fn => this[fn] = this.originalActions[fn])
    this.originalActions = {}
  },
  fireApi(method, url, data, options) {
    // Set default values
    var errorAction = options.errorAction ? options.errorAction : options.successAction
    jsonStatham[method].call(jsonStatham, url, data)
      .then(function (data) {
        var scopedData = options.JSONHead ? data[options.JSONHead] : data
        if (options.successAction) {
          AppDispatcher.dispatch({
            actionType: options.successAction,
            data: scopedData
          })
        }
        if (options.onSuccess)
          options.onSuccess.apply(null, [scopedData])
        if (options.successMsg) {
          AppDispatcher.dispatch({
            actionType: this.specialTypes.GOT_MSG,
            data: options.successMsg
          })
        }
      }.bind(this))
      .fail(function (data) {
        var scopedErrors = options.JSONHead ? data[options.JSONHead] : data
        var errors = scopedErrors ? scopedErrors.errors : data.errors
        tools.fireRollbarError(`ActionPrototype error (${method} ${url}):`, data)

        AppDispatcher.dispatch({
          actionType: errorAction,
          data: {errors}
        })
      })
  }
}

export default ActionPrototype
