export const arrayToHumanizedList = (textArray, conjunction = 'and') => {
  switch (textArray.length) {
    case 0:
    case 1:
      return textArray.toString()
    case 2:
      return textArray.join(` ${conjunction} `).trim()
    default:
      return `${textArray.slice(0, -1).join(', ')}, ${conjunction} ${textArray[textArray.length - 1]}`.trim()
  }
}

export const trimString = text => (text ? text.trim() : text)

/**
 * Compare two strings and return an integer representing which comes alphabetically first
 * when casing is ignored. Note that since this is all just built-in JavaScript functionality
 * I will not be adding tests around it.
 *
 * @param {String} stringOne The first `String` to be compared.
 * @param {String} stringTwo The second `String` to be compared.
 *
 * @returns {Number} `0` if they are alphabetically identical, `-1` if `stringOne` should come first,
 *.   and `1` if `stringTwo` should come first.
 */
export const compareCaseInsensitiveStrings = (stringOne, stringTwo) => (
  stringOne.toLowerCase().localeCompare(stringTwo.toLowerCase())
)
