import {dateToISOString} from './dateTools'
import {Map, List, Set} from 'immutable'

import {allPrimitives, dereferenceElements, rectifyConditionalMaps} from './plan_data/item'
import {capitalize} from './tools'
import {categoryItems} from './plan_data/category'
import {dateWithMaskFromForm} from './dateTools'

export const allByElementId = (list, elementIdToMatch) => list.filter(item => item.get('element-id') === elementIdToMatch)

export const groupId = response => response.get('group-id').first()

/**
 * Returns a string denoting the subheading of an item depending if the item has multiple response groups
 *
 * @param {List} the responses for an item filtered by response group
 * @param {Number} index of the response of the element being viewed
 *
 * @returns {String} name of the element being viewed
 */
export const responseGroupName = ({compoundResponses, index, elementName, isCompoundItem}) => {
  if (isCompoundItem)
    return compoundResponses.size > 1 ? `${elementName} (${index + 1} of ${compoundResponses.size})` : elementName

  return null
}

/**
 * Returns a list of `ids` of all primitive elements for an item
 *
 * @param {Map} a plan's item
 * @param {List} a plan's kits
 *
 * @returns {List} of `ids` of all primitive elements for an item
 */
export const elementIds = (item, kits) => allPrimitives(item, kits).toArray()

/* get the element id regardless of what format the field is in */
export const elementId = datum => (datum.get('elementId') ? datum.get('elementId') : datum.get('element-id'))

export const itemHasResponse = (item, kits, response) => elementIds(item, kits).includes(response.get('element-id'))

export const responseValue = response => response.get('value')

export const itemResponses = (item, kits, responses) => responses.filter(response => itemHasResponse(item, kits, response) && responseValue(response))

export const itemHasResponses = (item, responses, kits) => (
  responses.some(
    response => (
      elementIds(item, kits).includes(response.get('element-id')) && responseValue(response)
    )
  )
)

/**
 * @param {List} responses
 * @param {string} responsesGroupId that will be used to filter responses
 * @returns {List} of response that have the provided `responsesGroupId`
 */
export const responsesByGroupId = ({responses, responsesGroupId}) => responses.filter(response => response.get('group-id').includes(responsesGroupId))

/**
 * @param {Map} item for which the responses need to be found
 * @param {Map} kits
 * @param {List} responses of an everplan
 *
 * @returns {List} of the item response groups ids sorted by the created-at date
 */
export const itemResponseGroupIds = ({item, kits, responses}) => (
  itemResponses(item, kits, responses)
    .sortBy(response => dateToISOString(response.get('created-at')))
    .groupBy(response => response.getIn(['group-id', 0]))
    .keySeq()
)

/**
 * Returns all responses for an item that have a response, grouped by their groupdId, and sorted with the most recently created at
 * at the bottom.
 *
 * @param {Map} item for which the responses need to be found
 * @param {Map} kits
 * @param {List} responses of an everplan
 *
 * @returns {Map} of the item response groups, with the `groupId` as the key and the responses as the value sorted by the created-at date
 */
export const itemResponseGroupMap = ({item, kits, responses}) => itemResponseGroupIds({item, kits, responses})
  .reduce((responseGroup, responsesGroupId) => responseGroup.set(responsesGroupId, responsesByGroupId({responses, responsesGroupId})), Map())


/**
 * @param {Map} everplanResponse
 * @param {List} items for a particular category
 *
* @returns {List} of given items with the items response group map included
 */
export const itemsWithResponseGroupMap = ({items, responses, kits}) => items.reduce((collection, item) => {
  const responseGroupMap = itemResponseGroupMap({item, kits, responses})

  return responseGroupMap.isEmpty() ? collection : collection.push(item.set('responseGroupMap', responseGroupMap))
}, List())

export const categoryWithItemResponses = (category, corpMaxData) => {
  const responses = corpMaxData.everplanResponse.get('responses', List())
  if (responses.isEmpty())
    return List()

  const items = categoryItems(category, corpMaxData.items)

  const categoryItemsWithResponses = itemsWithResponseGroupMap({
    items,
    kits: corpMaxData.kits,
    responses
  })

  return categoryItemsWithResponses.sortBy(item => item.get('sort-order'))
}

export const itemsWithResponsesPerCategory = corpMaxData => {
  if (corpMaxData.everplanResponse.get('responses', List()).isEmpty()) {
    return List()
  } else {
    const reducer = (collection, category) => {
      const categoryWithItems = categoryWithItemResponses(category, corpMaxData)
      if (categoryWithItems.size)
        return collection.push(Map({name: category.get('name'), items: categoryWithItems}))
      else
        return collection
    }
    return (
      corpMaxData.categories
        .sortBy(category => category.get('sort-order'))
        .reduce(reducer, List())
    )
  }
}

/**
 *
 * @param {Map} item for which the responses need to be found
 * @param {Map} kits
 * @param {List} responses of an everplan
 *
 * @returns {List} of the item response groups sorted by the created-at date
 */
export const itemResponseGroups = (item, kits, responses) => itemResponseGroupMap({item, kits, responses}).toList()

export const updateResponseGroupOrder = ({responseGroupsOrder, updatedResponseGroups}) => {
  const updatedGroups = Set(updatedResponseGroups.keySeq())
  const newResponseGroups = updatedGroups.subtract(Set(responseGroupsOrder))

  return responseGroupsOrder.filter(response => updatedGroups.includes(response)).concat(newResponseGroups)
}

export const ownershipId = ownership => {
  const ownerOwnershipId = ownership.get('owner-ownership-id') || ownership.get('id')
  return parseInt(ownerOwnershipId, 10)
}

export const responseOwners = ({data, ownerships}) => {
  const ownershipIds = data.map(response => response.get('ownership-ids')).flatten().toSet().toList()
  const ownersName = ownershipIds.map(id => ownerships.find(ownership => ownershipId(ownership) === parseInt(id, 10)).get('first-name'))
  return ownersName.join(' and ').trim()
}

/**
 * Constucts a list of `responsesWithElements`, which is a response with its corresponding element if it exists, sorted
 * by the element order to preserve the view order.
 *
 * @param {List} responseGroup by groupId for a given item
 * @param {List} elements for a given item
 *
 * @returns {List} of the same group of responses with each response including the corresponding element and sorted
 */
export const sortedResponsesWithElements = ({responseGroup, elements}) => {
  let responsesList = List()

  responseGroup.forEach(response =>
    elements.find((element, index) => {
      if (element.get('id') === response.get('element-id')) {
        const responseWithElement = response.set('element', element).set('sort-order', index)
        responsesList = responsesList.push(responseWithElement)
      }
    })
  )

  return responsesList.sortBy(response => response.get('sort-order'))
}


/**
 * @param {List} responses
 * @returns {Map} of responses grouped by second groupId
 */
export const responsesByCompoundResponseGroup = responses => responses.groupBy(response => response.getIn(['group-id', '1']))

// TODO: change this stupid name manipulation once we choose how to handle URL slugs for categories:
export const categoryNameFromSlug = slug => slug.replace(/-/g, ' ').split(' ').map(word => capitalize(word)).join(' ')

/*
 * This returns a group of responses with the same first groupId with their corresponding element as part of each responseData
 * Used so we don't need to remunge the data multiple times to get the element for each response -- ZD
 *
 * @deprecated Do not use this method until it has been refactored and tests have been added. It uses the horrible `item`
 *    class which is really heavy and we are moving aware from. --BLR
 */
export const responsesData = (parentElement, responseGroup, kits, listMappings) => {
  const elements = rectifyConditionalMaps({
    responses: responseGroup,
    childElements: dereferenceElements(parentElement, kits),
    parentElement,
    kits,
    listMappings
  })

  return sortedResponsesWithElements({responseGroup, elements})
}


/**
 * @param {List} responses
 * @returns {OrderedMap} of all responses grouped by the first group id
 * @todo Replace usage of `responseGroups` with this especially on the preview page
*/
export const allResponseGroups = responses => responses.groupBy(response => response.getIn(['group-id', '0']))

/**
 * Returns the first value in the `group-id` of the first `Response` in a `List` of `Responses` grouped by their first `group-id` value.
 */
export const firstGroupId = responsesByFirstGroupId => responsesByFirstGroupId.getIn([0, 'group-id', 0])


/**
 * @param {List} responses from the formData which is why it has a type
 *
 * @returns {List} of responses updated with the correct mask and date format for any that are type date
*/
export const updateResponsesWithTypeDate = responses => (
  responses.map(response => {
    if (response.get('type') === 'DATE' && response.get('value'))
      return response.set('value', dateWithMaskFromForm(response.get('value')))
    else
      return response
  })
)

/**
 * @param {Map} topKitElement
 * @param {List} responses
 * @param {Map} kits
 * @param {Map} listMappings
 *
 * @returns {Boolean} to show if there is a file response present in the responses.
*/
export const hasFileResponse = ({topKitElements, responses, kits, listMappings}) => (
  topKitElements.some(topKitElement => {
    const responsesWithElements = responsesData(topKitElement, responses, kits, listMappings)
    return responsesWithElements.some(response => response.getIn(['element', 'type']) === 'FILE')
  })
)
