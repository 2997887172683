const Block = props => {
  if (__DEV__) {
    return (
      <span className='dev-block'>
        {props.children}
      </span>
    )
  }

  return <noscript />
}

export default Block
