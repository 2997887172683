import TestPrep from './TestPrep'

const mixpanelAvailable = typeof mixpanel !== 'undefined'

const MixpanelLogger = {
  track(eventName, payload = {}) {
    if (mixpanelAvailable)
      mixpanel.track(eventName, payload)
  },
  identify(user) {
    if (mixpanelAvailable && user.source_id) {
      mixpanel.identify(user.source_id)
      mixpanel.people.set({
        internal: user.internal,
        partner_id: user.partner_id,
        user_category: user.user_category
      })
    }
  },
  reset() {
    if (mixpanelAvailable) mixpanel.reset()
  }
}

export default {
  ...TestPrep,
  ...MixpanelLogger
}
