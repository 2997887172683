// stolen from: https://github.com/gaearon/react-side-effect --BJK

import PropTypes from 'prop-types'

import React from 'react'
import withSideEffect from 'react-side-effect'

class PageTitle extends React.Component {
  render() {
    if (this.props.children) {
      return React.Children.only(this.props.children)
    } else  {
      return null
    }
  }
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
}

var reducePropsToState = propsList => {
  var innermostProps = propsList[propsList.length - 1]
  if (innermostProps) {
    return `${innermostProps.title} | Everplans`
  } else {
    return "Everplans"
  }
}

var handleStateChangeOnClient = title => {
  if (typeof document !== 'undefined')
    document.title = title || '';
}

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient
)(PageTitle)
