import PropTypes from 'prop-types'

import showInputIcon from '../../shared_assets/v2/unmask-icon.svg'
import hideInputIcon from '../../shared_assets/v2/mask-icon.svg'

import Flyout from './PasswordFlyout'
import PasswordInput from './PasswordInput'
import Input from './Input'
import criteria from '../password_criteria.json'
import {If} from '../deprecated/IfFlag'
import CompoundFormComponent from './CompoundFormComponent'
import Wrapper from './Wrapper'

import './password.scss'

export default class Password extends CompoundFormComponent {
  constructor(props) {
    super(props)
    this.state = {
      showFlyout: false,
      showPassword: false
    }

    this.onPassBlur = this.onPassBlur.bind(this)
    this.onPassFocus = this.onPassFocus.bind(this)
    this.validatePassword = this.validatePassword.bind(this)
    this.toggleMask = this.toggleMask.bind(this)
  }

  onPassBlur() {
    this.setState({showFlyout: false})
  }

  onPassFocus() {
    this.setState({showFlyout: true})
  }

  toggleMask(event) {
    event.preventDefault()

    this.setState({showPassword: !this.state.showPassword})
  }

  validatePassword(event) {
    var password = event.target.value
    var isValid = true
    criteria.forEach(criterion => {
      criterion.isValid = criterion.regex.test(password)
      isValid = isValid && criterion.regex.test(password)
    })
    if (isValid) {
      setTimeout(() => {
        this.setState({showFlyout: false})
        $('input[name="show_password"]').first().prop('checked', false)
      }, 1000)
    } else {
      this.setState({showFlyout: true})
    }
  }

  render() {
    return (
      <div className='password'>
        <div className='input-and-mask-toggle-container'>
          <Wrapper label={this.props.label} name={this.props.name} errors={this.errors()}>
            <Input
              autoComplete='new-password'
              className='password'
              name='password'
              type={this.state.showPassword ? 'text' : 'password'}
              onBlur={this.onPassBlur}
              onFocus={this.onPassFocus}
              placeholder={this.props.placeholder}
              label={null}
              wrap={false}
              onChange={this.validatePassword}
            />
            <button className='mask-icon-and-text' onClick={this.toggleMask} type='button'>
              <img alt='' className='mask-icon' src={this.state.showPassword ? hideInputIcon : showInputIcon} />
              {this.state.showPassword ? 'Hide' : 'Show'}
            </button>
          </Wrapper>
        </div>
        <If flag={this.state.showFlyout && this.props.showFlyout}>
          <Flyout header='Password must contain at least:' criteria={criteria} />
        </If>
        {this.props.showConfirmation &&
          <PasswordInput
            autoComplete='new-password'
            className='password-confirmation'
            label='Confirm password'
            name='password_confirmation'
            placeholder={this.props.placeholder ? `Confirm ${this.props.placeholder.toLowerCase()}` : null}
            type='password'
          />
        }
      </div>
    )
  }
}

Password.propTypes = {showFlyout: PropTypes.bool}
Password.defaultProps = {
  showConfirmation: true,
  showFlyout: true,
  validator: (name, data) => {
    var password = data.password ? data.password : ''
    if (password.length === 0)
      return {name: 'password', errors: ["Password can't be empty."]}

    if (password !== data.password_confirmation)
      return {name: 'password_confirmation', errors: ["Passwords don't match. Please try again."]}

    var isValid = true
    criteria.forEach(criterion => {
      isValid = isValid && criterion.regex.test(password)
    })

    if (!isValid)
      return {name: 'password', errors: ['Please enter a valid password.']}
  }
}
