import PropTypes from 'prop-types'
import React from 'react'

const MODAL_CONTEXT_TYPES = {
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func,
  showModal: PropTypes.func
}

export default function wrapModal(Modal) {

  class ModalWrapper extends React.Component {
    constructor(props) {
      super(props)
      this.state = {showModal: false}
    }
    showModal(event) {
      if (event)
        event.preventDefault()
      this.setState({showModal: true})
    }
    closeModal() {
      this.setState({showModal: false})
    }
    modalClosed(event) {
      if (event && event.preventDefault) // Check that the method exists to fix custom closers on new modals. --BLR
        event.preventDefault()
      this.closeModal()
    }
    getChildContext() {
      return {
        showModal: this.showModal.bind(this),
        closeModal: this.closeModal.bind(this),
        modalClosed: this.modalClosed.bind(this)
      }
    }

    render() { return <Modal showModal={this.state.showModal} {...this.props} /> }
  }

  ModalWrapper.childContextTypes = MODAL_CONTEXT_TYPES

  return ModalWrapper
}

export { MODAL_CONTEXT_TYPES } // Expose this object so it doesn't need to be copied to a bunch of other files. --BLR
