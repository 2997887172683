import Autosuggest from 'react-autosuggest'

import InputBase from './InputBase'
import Wrapper from './Wrapper'

import theme from './autosuggestInput.scss?inline'


export default class AutosuggestInput extends InputBase {
  constructor(props) {
    super(props)

    this.setInitialState(props)
    this.state = {
      value: props.defaultValue,
      suggestions: []
    }

    this.onChange = this.onChange.bind(this)
    this.onFetch = this.onFetch.bind(this)
    this.onClear = this.onClear.bind(this)
  }

  renderSuggestion(suggestion) { return <div>{suggestion}</div> }

  // This is a function because the component expects a function
  getValue(suggestion) { return suggestion }

  // This function checks if the input that matches any content of the suggestions
  getMatch(value) {
    const inputValue = value.trim().toLowerCase()
    const suggestions = this.props.suggestions || []

    if (inputValue.length === 0)
      return []
    else
      return suggestions.filter(suggestion => suggestion.toLowerCase().match(inputValue))
  }

  onChange(event, {newValue}) {
    this.setState({value: newValue})
    this.context.setFormValue(this.props.name, newValue)
  }

  onFetch({value}) { this.setState({suggestions: this.getMatch(value)}) }

  onClear() { this.setState({suggestions: []}) }

  render() {
    const inputProps = {
      name: this.props.name,
      placeholder: this.props.placeholder,
      value: this.state.value,
      onChange: this.onChange,
      className: this.props.className
    }

    return (
      <Wrapper {...this.props} errors={this.errors()} >
        <div className='autosuggest'>
          <Autosuggest
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onFetch}
            onSuggestionsClearRequested={this.onClear}
            getSuggestionValue={this.getValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            theme={theme}
          />
        </div>
      </Wrapper>
    )
  }
}

AutosuggestInput.defaultProps = {defaultValue: ''}
