import PropTypes from 'prop-types'
import React from 'react'
import InputBase from './InputBase'

export default class CompoundFormComponent extends InputBase {
  setFormValue(key, value) {
    return super.setFormValue(key, value)
  }

  errors() {
    return super.errors()
  }
}

CompoundFormComponent.childContextTypes = {setFormValue: PropTypes.func, errors: PropTypes.func}
CompoundFormComponent.contextTypes = {setFormValue: PropTypes.func, errors: PropTypes.func}
