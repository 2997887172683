// Action types
export const ADD_KEY_FIELD_TOOL_DATA = 'add_key_field_tool_data'
export const DELETE_KEY_FIELD_TOOL_DATA = 'delete_key_field_tool_data'
export const FETCH_KEY_FIELDS_TOOL_DATA = 'fetch_key_fields_tool_data'

// Action creators
export const addKeyFieldToolData = keyField => ({
  type: ADD_KEY_FIELD_TOOL_DATA,
  payload: keyField
})

export const deleteKeyFieldToolData = keyField => ({
  type: DELETE_KEY_FIELD_TOOL_DATA,
  payload: keyField
})

export const fetchKeyFieldsToolData = keyFieldsMapping => ({
  type: FETCH_KEY_FIELDS_TOOL_DATA,
  payload: keyFieldsMapping
})

