import {fromJS, List, Map} from 'immutable'

import {chooseApiV2Url} from './urlTools'
import extra_storage from './extra_storage'
import {fireRollbarError} from './sessionTools'


export const baseApiV2Config = () => ({ // Must be a function so authToken is grabbed when request is made, not when this file is loaded. --BLR
  headers: {
    Accept: 'application/vnd.api+json',
    Authorization: `Token token=${extra_storage.getItem('authToken')}`,
    'Content-Type': 'application/vnd.api+json'
  },
  onFail: response => {
    fireRollbarError(
      'API v2 error', // Temporary message since I don't know how to access the config Map here yet. --BLR
      response
    )
    return response
  },
  onSuccess: response => response
})

export const mergeConfigs = (...configs) => configs.reduce((finalConfig, config) => finalConfig.mergeDeep(fromJS(config)), Map())

export const apiV2Config = config => mergeConfigs(baseApiV2Config(), config, {url: chooseApiV2Url(config)}).delete('resource')

export const constructPayload = ({attributes, id, type}) => ({
  data: {
    attributes,
    id,
    type
  }
})

export const cleanResponse = data => {
  const immutableResponse = fromJS(data)

  // Ensure everything has the proper ID if it's a list of items (e.g., an #index request):
  if (List.isList(immutableResponse))
    return immutableResponse.map(datum => datum.get('attributes').set('id', datum.get('id')))

  // Otherwise let the consumer of the data handle it:
  return immutableResponse
}

