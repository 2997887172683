import PropTypes from 'prop-types'
import React from 'react'

import PageTitle from '../shared_components/PageTitle'


const Title = props => {
  if (__DEV__ && props.title)
    return <PageTitle title={props.title.toString()} />

  return <noscript />
}

Title.defaultProps = {title: null}

Title.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default Title
