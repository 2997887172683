import KeyMirror from 'keymirror'

import AppDispatcher from '../../../lib/ep-dispatcher'
import MixpanelLogger from '../../../lib/MixpanelLogger'

const SessionApiResponse = {
  Types: KeyMirror({
    WEB_USER_SIGNED_IN: null,
    WEB_USER_SIGN_IN_REJECTED: null,
    WEB_TFA_CODE_SENT: null,
    WEB_CURRENT_USER_RECEIVED: null,
    WEB_NO_CURRENT_USER: null,
    WEB_USER_SIGNED_OUT: null
  }),

  userSignedIn(user) {
    MixpanelLogger.identify(user)
    MixpanelLogger.track('user_login')

    AppDispatcher.dispatch({
      actionType: this.Types.WEB_USER_SIGNED_IN,
      user
    })
  },

  userSignedOut() {
    MixpanelLogger.reset()

    AppDispatcher.dispatch({
      actionType: this.Types.WEB_USER_SIGNED_OUT
    })
  },

  userSignInRejected(error) {
    MixpanelLogger.reset()

    AppDispatcher.dispatch({
      actionType: this.Types.WEB_USER_SIGN_IN_REJECTED,
      error
    })
  },

  tfaCodeSent(primaryPhone, secondaryPhone, phoneSentTo, lastEnabledAt) {
    AppDispatcher.dispatch({
      actionType: this.Types.WEB_TFA_CODE_SENT,
      primaryPhone,
      secondaryPhone,
      phoneSentTo,
      lastEnabledAt
    })
  },

  currentUserReceived(user) {
    MixpanelLogger.identify(user)

    AppDispatcher.dispatch({
      actionType: this.Types.WEB_CURRENT_USER_RECEIVED,
      data: user
    })
  },

  noCurrentUser() {
    MixpanelLogger.reset()

    AppDispatcher.dispatch({
      actionType: this.Types.WEB_NO_CURRENT_USER
    })
  }
}

export default SessionApiResponse
