import SessionStore from '../auth/src/stores/SessionStore'

class FeatureFlags {
  init(flags) {
    this.flags = {}
    flags.map((f)=> { this.flags[f.flag]= f.status })
  }
  user() { return SessionStore.getState().currentUser || {} }
  isTestUser() { return !!this.user().test_user}
  flagStatus(flag) { return this.flags && this.flags[flag] ? String(this.flags[flag]).toUpperCase() : 'DISABLED' } // if we don't know the flag, assume it's disabled
  isEnabledForUser(flag) { return this.flagStatus(flag) === 'TESTONLY' && this.isTestUser() }
  isEnabled(flag) { return this.flagStatus(flag) === 'ENABLED' || this.isEnabledForUser(flag) }
}

export default new FeatureFlags() // specifically want a singleton
