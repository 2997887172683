/** @class If
 *
 * @deprecated Do not use this as it is buggy and leads to strange formatting issues.
 * @desc
 * Component to conditionally show its +children+ based on whether a strictly boolean
 *   +flag+ is +true+.
 */

import React from 'react'

export class If extends React.Component {
  render() {
    var children = Array.isArray(this.props.children) ? <span>{this.props.children}</span> : this.props.children //this avoids adding unncessary container flags unless it's absolutely unavoidable
    if (this.props.flag === true)
      return children
    else
      return null
  }
}


/** @class Else
 *
 * @deprecated Do not use this as it is buggy and leads to strange formatting issues.
 * @desc
 * Component to conditionally show its +children+ based on whether a strictly boolean
 *   +flag+ is +false+.
 */
export class Else extends If {
  render() {
    return <If flag={this.props.flag === false}>{this.props.children}</If>
  }
}

/** @class Unless
 *
 * @deprecated Do not use this as it is buggy and leads to strange formatting issues.
 * @desc
 * Alias of +Else+
 */
export class Unless extends Else {} // alias for railsy like alternatives in readibility
