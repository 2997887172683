/** @module NewLogger
 *
 * @desc
 * Writes events to our server-side event log via API v2.
 *
 * @todo Once we fully move over to this module we need to remove the
 *.   old `Logger` and rename this to just `Logger`.
 *
 * @note Because `constructPayload` is part of this module and not a
 *.   standalone method you cannot use `import {log} from './NewLogger'`
 *.   because `this` will be incorrect.
 */

import assign from 'object-assign'

import apiV2 from './apiV2'
import {generateSessionId} from './tools'
import TestPrep from './TestPrep'


const Logger = {
  log({name, payload}) {
    if (name) {
      apiV2.POST({
        body: this.constructBody({name, payload}),
        resource: 'event-logs'
      })
    }
  },

  constructBody({name, payload}) {
    return {
      data: {
        type: 'event-logs',
        attributes: {
          'event-name': name,
          payload: this.constructPayload(payload)
        }
      }
    }
  },

  constructPayload(eventPayload) {
    const basePayload = {
      mode: 'app',
      session_id: generateSessionId()
    }

    if (eventPayload)
      return {...eventPayload, ...basePayload}
    else
      return basePayload
  }
}

export default assign(Logger, TestPrep)
