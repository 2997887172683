import PropTypes from 'prop-types'
import Checkbox from './Checkbox'
import Validators from './Validators'
import * as Links from '../Links'

import './serviceTerms.scss'

const ServiceTerms = ({name}) => (
  <Checkbox label='' className='service-terms agreement-box' name={name} validator={Validators.required}>
    <span> I agree to the <Links.Terms target='_blank'>Terms of Service</Links.Terms> and <Links.Privacy target='_blank'>Privacy Policy</Links.Privacy>.</span>
  </Checkbox>
)

export default ServiceTerms

ServiceTerms.defaultProps = {
  validator: (name, data) => {
    if (data[name] !== true)
      return {name, errors: ['You must agree to the Terms of Service and Privacy Policy.']}
  },
  name: 'agreement'
}

ServiceTerms.propTypes = {
  name: PropTypes.string.isRequired
}
