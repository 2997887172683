import PropTypes from 'prop-types'

import Tooltip from './Tooltip'
import {RenderNullComponent} from '../../../lib/tools'

import './announcementTooltip.scss'

const AnnouncementTooltip = ({showTooltip, hideTooltip, ...otherProps}) => {
  if (showTooltip) {
    return (
      <div className='announcement-tooltip'>
        <div onClick={hideTooltip} className='overlay' />{/**
         * overlay here prevents user from clicking anything on the screen where the tooltip is been used until it's dismissed - Atanda
         */}
        <Tooltip {...otherProps} />
      </div>
    )
  } else {
    return <RenderNullComponent />
  }
}

AnnouncementTooltip.propTypes = {
  hideTooltip: PropTypes.func,
  showTooltip: PropTypes.bool
}

export default AnnouncementTooltip
