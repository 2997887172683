import {createHashHistory} from 'history'
import {useEffect} from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {useRouterHistory, Router} from 'react-router'

import {ApolloProvider} from '@apollo/client'
import {ThemeProvider} from '@mui/material/styles'
import {Globals, useReducedMotion} from '@react-spring/web'

import {initializeAxiosConfiguration} from './axiosUtils'
import configureStore from '../configureStore'
import extra_storage from './extra_storage'
import graphqlClient from '../graphql'
import theme from '../styles/theme'
import {NotificationContextProvider} from '../shared_components/notifications/NotificationContext'

const defaultHistory = useRouterHistory(createHashHistory)()
export const store = configureStore()

export const sanitizeQueryParams = url => {
  const urlParts = url.split('?')
  let params = urlParts[1]
  if (urlParts.length < 2)
    return url
  params = params.split('&').filter(param => param.indexOf('_k='))
  return params.length > 0 ? `${urlParts[0]}?${params.join('&')}` : urlParts[0]
}

export default (routes, domElement, callback, history = defaultHistory) => {
  const logToGa = () => {
    var pageviewUrl = location.pathname + location.search + sanitizeQueryParams(location.hash)
    if (window.ga)
      ga('send', 'pageview', {page: pageviewUrl})
    else if (window._gaq) // also support legacy GA that may be in rails app
      _gaq.push(['_trackPageview', pageviewUrl])
  }

  const logPageview = () => {
    if (location.hash === '#/')
      return
    if (window.ga || window._gaq)
      logToGa()
    else
      setTimeout(() => { logToGa() }, 1000)
  }

  history.listen(location => {
    if (callback)
      callback(location)
    logPageview()
  })

  initializeAxiosConfiguration({
    store,
    authToken: extra_storage.getItem('authToken')
  })

  if (!domElement || !domElement.get(0))
    return history // in case there is nothing to bind too, just silently fail

  function AppWithCallbackAfterRender() {
    const reduceMotion = useReducedMotion()

    useEffect(() => {
      Globals.assign({skipAnimation: reduceMotion})
    }, [reduceMotion])

    useEffect(() => {
      logPageview()
    })

    // we noticed in may of 2018, right when we pushed the update to react 15, there was a steep drop
    // in visits to the signup page, then I noticed that there was a breaking change in the history npm module
    // "Breakage: history.listen no longer calls the callback synchronously once. Use history.getCurrentLocation instead"
    // to fix: if the user is direclty navigating to a URL it won't trigger the history transition, on that first pageview
    // so-- we should log the page view this one time, after the dom successfully mounts --BJK
    return (
      <ThemeProvider theme={theme}>
        <NotificationContextProvider>
          <Provider store={store}>
            <ApolloProvider client={graphqlClient}>
              <Router history={history} routes={routes} />
            </ApolloProvider>
          </Provider>
        </NotificationContextProvider>
      </ThemeProvider>
    )
  }

  const root = createRoot(domElement.get(0))
  root.render(<AppWithCallbackAfterRender />)

  return history
}

/** Renders a component on the dom, but meant to be a second place on the dom where you add
 * a component. This is mainly used for widgets, and things that are attached outside our main app canvase */
export const extraRender = (domElement, Component, props) => {
  const root = createRoot(domElement.get(0))
  root.render(
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  )
}
