import UrlParse from 'url-parse'

import routerUtils from './routerUtils'
import {pendingOrProcessedDeathReport} from './corpMaxDeputyTools'
import {routeContainsVerb} from '../dashboard/src/lib/corpMaxRouteMatcher'
import {categorySlug} from './plan_data/category'
import {List} from 'immutable'


// /////////////////////////////////////////////////////////////////////////////
// URL parsing and cleanup

export const extractHostname = url => (
  new UrlParse(
    // Fall back to an empty string if no `url` is passed in.
    url || '',
    // Ensure that the library doesn't ever fall back to the browser's current location if the `url`
    // _looks_ like it's relative. Annotated in this PR on the library:
    //   https://github.com/unshiftio/url-parse/pull/65/files
    {}
  ).hostname
)

export const stripLeadingSlash = input => input.replace(/^\/*/, '')

export const stripTrailingSlash = input => input.replace(/\/+$/, '')

export const stripLeadingSlashHash = input => input.replace(/^\/*#*\/*/, '') // Regex strips leading /#/, #/, and /

/**
 * Returns the web-apps environment name of the current environment based on the hostname in the passed
 * in URL if the URL is an Everplans environment. Returns `undefined` otherwise.
 *
 * @todo Consider expanding to include the Rails app and content site since we sometimes inject the widget into
 *.   both of those and might want to have this information available there too.
 */
export const environmentName = url => {
  switch (extractHostname(url)) {
    case 'localhost':
      return 'LOCALHOST'
    case 'webapps.everplansdev.com':
      return 'DEV'
    case 'webapps.everplansdev1.com':
      return 'DEV1'
    case 'webapps.everplansdev2.com':
      return 'DEV2'
    case 'webapps.everplanstest.com':
      return 'STAGING'
    case 'webapps.everplans.com':
      return 'PRODUCTION'
    default:
  }
}

// /////////////////////////////////////////////////////////////////////////////
// Basic URL constructors

export const railsUrl = path => `${API_HOST}/${stripLeadingSlash(path)}`

export const webAppsUrl = path => `${WEBAPPS_HOST}/#/${stripLeadingSlashHash(path)}`

export const corpMaxWrapperUrl = path => `${WEBAPPS_HOST}/e/#/cm/${stripLeadingSlashHash(path)}`

/**
 * Checks if this component is being mounted under the correct Express route.
 * The `/e` route is just an alias so the the corp max urls are a little more sane. Purely cosmetic. --BJK
 */
export const isCorrectExpressRoute = routeContainsVerb(window.location, ['/dashboard/', '/e/'])

/** Ensure that all users are on one of the correct top-level Express routes based on migration status. */
export const enforceCorrectExpressRoute = () => {
  if (!isCorrectExpressRoute || window.location.pathname === '/dashboard/')
    return routerUtils.setLocation(corpMaxWrapperUrl(window.location.hash.replace(/\bcm\/\b/, '')))
}

// /////////////////////////////////////////////////////////////////////////////
// Api V2

export const apiV2Url = pathname => `${API_HOST}/api/v2/${stripLeadingSlash(pathname)}`

export const apiV2IndexUrl = resource => apiV2Url(resource)

export const apiV2ShowUrl = (resource, id, params) => apiV2Url(`${resource}/${id}${params}`)

export const chooseApiV2Url = config => {
  if (config.get('id'))
    return apiV2ShowUrl(config.get('resource'), config.get('id'), config.get('params') || '')
  else
    return apiV2IndexUrl(config.get('resource'))
}

// /////////////////////////////////////////////////////////////////////////////
// Corp Max

export const accountSettingsUrl = corpMaxWrapperUrl('account-settings')

export const tfaUrl = corpMaxWrapperUrl('tfa-setting')

export const corpMaxDashboardUrl = corpMaxWrapperUrl('dashboard')

export const corpMaxDeputiesUrl = corpMaxWrapperUrl('deputies')

export const corpMaxDashboardPath = 'cm/dashboard'

export const accountSettingsPath = 'cm/account-settings'

export const editCreditCardPath = 'cm/account-settings/credit-card-form'

export const corpMaxEverplanPreviewPath = everplanId => `cm/everplan-preview/${everplanId}`

export const sharedUrlPath = (itemSlug, groupId, elementId) => `item/${itemSlug}/view${groupId ? `/${groupId}` : ''}${elementId ? `/${elementId}` : ''}`

export const goToParentCategory = pathname => routerUtils.push(pathname.substring(0, pathname.indexOf('/item/')))

export const constructItemQuery = query => {
  if (query) {
    const type = query.type
    const condValue = query.conditional_value
    if (type && condValue)
      return `?type=${type}&conditional_value=${condValue}`
    else if (type)
      return `?type=${type}`
    else if (condValue)
      return `?conditional_value=${condValue}`
    else
      return ''
  }
  return ''
}

export const pagePath = (path, pathString) => (path.includes(pathString) ? path.substring(0, path.indexOf(`/${pathString}/`)) : stripTrailingSlash(path))

export const preCategoryPagePath = path => pagePath(path, 'category')

export const preAccountEntryPath = path => pagePath(path, 'account-entry')

export const accountEntrySelectPath = ({itemSlug, path, queryParams}) => `${path}/account-entry/${itemSlug}${constructItemQuery(queryParams)}`

export const editItemPath = ({itemSlug, slug, groupId, path, queryParams}) => `${preCategoryPagePath(path)}/category/${slug}/item/${itemSlug}/edit/${groupId}${constructItemQuery(queryParams)}`

export const editCompoundItemPath = ({itemSlug, slug, groupId, path, queryParams}) => `${preCategoryPagePath(path)}/category/${slug}/item/${itemSlug}/edit/compound/${groupId}${constructItemQuery(queryParams)}`

export const addMorePath = ({itemSlug, slug, groupId, path, queryParams}) => `${preCategoryPagePath(path)}/category/${slug}/item/${itemSlug}/add-more/${groupId}${constructItemQuery(queryParams)}`

export const newItemPath = ({itemSlug, slug, elementId, path, queryParams}) => `${preCategoryPagePath(path)}/category/${slug}/item/${itemSlug}/new${elementId ? `/${elementId}` : ''}${constructItemQuery(queryParams)}`

export const newCompoundItemPath = ({itemSlug, slug, elementId, groupId, path, queryParams}) => `${preCategoryPagePath(path)}/category/${slug}/item/${itemSlug}/new/compound${elementId ? `/${elementId}` : ''}${groupId ? `/${groupId}` : ''}${constructItemQuery(queryParams)}`

export const viewItemPath = ({itemSlug, slug, groupId, elementId, path, queryParams}) => `${preCategoryPagePath(path)}/category/${slug}/${sharedUrlPath(itemSlug, groupId, elementId)}${constructItemQuery(queryParams)}`

/**
 * Redirects non-admin users to dashboard from CM tools
 * @param {Map} userConfig
 * @returns {void}
 */
export const redirectToDashboardFromTools = ({userConfig}) => {
  if (!userConfig.isEmpty() && !userConfig.get('admin'))
    routerUtils.push(corpMaxDashboardPath)
}

export const redirectCorpMaxAccountInformation = currentUser => routerUtils.setLocation(corpMaxWrapperUrl(`account-information/${currentUser.id}`))

export const redirectCorpMaxTfa = () => routerUtils.setLocation(tfaUrl)

export const redirectToDeputyPreview = everplan => routerUtils.push(`cm/everplan-preview/${everplan.get('id')}`)

// /////////////////////////////////////////////////////////////////////////////
// Corp Max Sharing After Death

export const corpMaxRemoveDeputyPath = ownershipId => `cm/deputies/${ownershipId}/remove`

export const corpMaxDeputyViewingHistoryPath = deputyId => `cm/deputies/${deputyId}/viewing-history`

export const corpMaxManageDeputyPermissionsPath = ownershipId => `cm/deputies/${ownershipId}/manage-permissions`

// report death url string
export const deathReportUrl = ({everplanId, isHousehold, deathReports}) => `cm/report-death/${everplanId}/${isHousehold && !(pendingOrProcessedDeathReport(deathReports)) ? 'household' : '1'}`

// /////////////////////////////////////////////////////////////////////////////
// Sharing After Death

export const removeDeputyPath = deputyId => `/deputies/${deputyId}/remove`

export const manageDeputyPermissionsPath = deputyId => `/deputies/${deputyId}/manage-permissions`

export const manageDeputyInvitePermissionsPath = inviteId => `/deputies/${inviteId}/manage-invite-permissions`

// /////////////////////////////////////////////////////////////////////////////
// Methods to actually go to specific routes

export const goToPaymentForm = (query = null) => routerUtils.push(`cm/account-settings/payment-form${query}`)

export const isWhitelistedDomain = url => {
  const whitelistedDomains = [extractHostname(WEBAPPS_HOST), extractHostname(API_HOST)]
  const hostname = extractHostname(url)

  return whitelistedDomains.includes(hostname)
}

export const constructEngagementsQuery = query => {
  if (query.nextPath)
    return {...query, nextPath: encodeURIComponent(query.nextPath)}
  else
    return query
}

/**
 * Checks to make sure that only whitelisted domains are allowed for redirect
 * then returns a string based on that.
 * @param {String} nextPath
 * @returns {String} nextPath if domain is allowed or dashboard url if not
 */
export const normalizeNextPath = nextPath => (
  (nextPath && !isWhitelistedDomain(nextPath)) ? corpMaxDashboardUrl : nextPath
)

/**
 * Constructs a path for the specified category
 * @param {Map} category - required
 * @returns {String} category path
 */
export const categoryPath = category => `cm/category/${categorySlug(category)}`

/**
 * Constructs the referral URL for agent landing page
 * @param {Map} organizationReferralCode - required
 * @param {Map} advisorReferralCode - required
 * @param {string} urlType
 * @returns {String} agent referral URL
 */
export const agentReferralUrl = ({organizationReferralCode, firmUserReferralCode, urlType = 'advisor'}) => (
  `${EVERPLAN_REFERRAL_HOST}/${urlType === 'advisor' ? 'a' : 'c'}/${firmUserReferralCode}${organizationReferralCode}`
)


/**
 * Constructs content site's help url
 * @param {Boolean} advisor - shows whether current user clicking help from the account dropdown is an advisor or not
 * @param {Boolean} advisorResources - it's true when the link is for resources on the pro dashboard
 * @returns {String} help URL
 */
export const helpUrl = ({advisor, advisorResources}) => {
  const baseUrl = (advisor || advisorResources) ? 'https://everplanspro.zendesk.com' : 'https://everplans.zendesk.com'
  let advisorResourceCenter = ''

  if (advisorResources)
    advisorResourceCenter = 'hc/en-us/articles/215786958-Everplans-Professional-Resource-Center'

  return `${baseUrl}/${advisorResourceCenter}`
}

export const goToPremiumAccessModal = ({itemSlug = null, currentPath = null}) => {
  let query = `prevPath=${encodeURIComponent(currentPath || 'cm/dashboard')}`

  if (itemSlug) query += `&itemSlug=${itemSlug}`

  return routerUtils.push(`cm/premium-access-modal?${query}`)
}
