const WarningIconCircle = props => (
  <svg {...props} viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' strokeWidth='1' fillRule='evenodd'>
      <path d='M27.3913043,15 C27.3913043,21.8325 21.8325,27.3913043 15,27.3913043 C8.1675,27.3913043 2.60869565,21.8325 2.60869565,15 C2.60869565,8.1675 8.1675,2.60869565 15,2.60869565 C21.8325,2.60869565 27.3913043,8.1675 27.3913043,15 M30,15 C30,6.72913043 23.2708696,0 15,0 C6.72913043,0 0,6.72913043 0,15 C0,23.2711957 6.72913043,30 15,30 C23.2708696,30 30,23.2711957 30,15' id='Fill-1' />
      <path d='M16.3043478,15.7394022 L16.3043478,9.67288043 C16.3043478,8.95255435 15.7203261,8.36853261 15,8.36853261 C14.2796739,8.36853261 13.6956522,8.95255435 13.6956522,9.67288043 L13.6956522,15.7394022 C13.6956522,16.4597283 14.2796739,17.04375 15,17.04375 C15.7203261,17.04375 16.3043478,16.4597283 16.3043478,15.7394022' id='Fill-4' />
      <path d='M16.1543478,21.1555109 C16.4576087,20.8493152 16.6304348,20.4319239 16.6304348,20.001163 C16.6304348,19.5707283 16.4576087,19.1500761 16.1543478,18.8468152 C15.8478261,18.5435543 15.4301087,18.3707283 15,18.3707283 C14.5695652,18.3707283 14.148913,18.5435543 13.8456522,18.8468152 C13.5423913,19.1500761 13.3695652,19.5707283 13.3695652,20.001163 C13.3695652,20.4319239 13.5423913,20.8493152 13.8456522,21.1555109 C14.148913,21.4590978 14.5695652,21.6315978 15,21.6315978 C15.4301087,21.6315978 15.851087,21.4590978 16.1543478,21.1555109' id='Fill-6' />
    </g>
  </svg>
)

WarningIconCircle.defaultProps = {
  height: '30px',
  width: '30px'
}

export default WarningIconCircle

