import AppDispatcher from '../lib/ep-dispatcher'
import assign from 'object-assign'
import {EventEmitter} from 'events'

// This factory method will return a fully baked store. The following parameters are all optional

/*
 *  - registerActions - this is an array of action types to auto register with the app dispatcher. The callback will
 *  be the receiveData method.
 *
 *  - extendType - this is an object prototype which will be applied to the store. Note, only a shallow
 *  copy will occur. Use this to extend the default behavior of the store pattern.
 *
 *  - registerMessageAction - this is an action type to auto register with the app dispatcher. The callback
 *  here is the receiveMsg callback.
 *
 *  In general, 95% of functionality can be accomplished using these three. But you can still register
 *  your own callbacks, add your own methods, etc..

*/

const factory = (registerActions, extendType, registerMessageAction) => {
  const StorePrototype = assign(EventEmitter.prototype, {
    errors: [],
    msg: null,
    data: {},
    emitChange() { this.emit('CHANGE') },
    addChangeListener(callback) { this.on('CHANGE', callback) },
    removeChangeListener (callback) { this.removeListener('CHANGE', callback) },
    getMsg() { return this.msg },
    setMsg(msg) { this.msg = msg },
    clearFlash() {
      this.msg = null
      this.emitChange()
    },
    getErrors() { return this.errors },
    pushError(error) { this.errors.push(error) },
    setErrors(errors) { this.errors = errors },
    clearErrors() { this.errors = [] },
    hasErrors() { return (this.errors.length > 0) },
    setData(data) { this.data = data },
    getData() { return this.data },
    getState() { return {data: this.data, errors: this.errors, message: this.msg} },
    clearState() { this.setData({}) },
    receiveData(data) {
      if (data.errors) {
        this.setErrors(data.errors)
      } else {
        this.setData(data)
        this.clearErrors()
      }
      this.emitChange()
    },
    receiveMsg(msg) {
      this.setMsg(msg)
      this.clearErrors()
      this.emitChange()
    },
    registerReceiveCallback(actionType) {
      return AppDispatcher.register(action => {
        if (action.actionType === actionType)
          this.receiveData(action.data)
      })
    },
    registerMessageCallback(actionType) {
      return AppDispatcher.register(action => {
        if (action.actionType === actionType)
          this.receiveMsg(action.data)
      })
    },
    update(data) {
      this.setData(data)
      this.emitChange()
    }
  })

  let store = Object.create(StorePrototype)
  if (registerActions)
    (Array.isArray(registerActions) ? registerActions : [registerActions]).forEach(action => store.registerReceiveCallback(action))
  if (extendType)
    store = assign(store, extendType)
  if (registerMessageAction)
    store.registerMessageCallback(registerMessageAction)
  return store
}

export default factory
