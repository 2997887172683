import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import AccountDropdown from './AccountDropdown'
import Container from '../../lib/Container'
import Dev from '../../dev_only'
import * as Links from '../Links'
import Logo from '../Logo'
import EverplansBrandedCobranding from './EverplansBrandedCobranding'
import PoweredBy from './PoweredBy'
import ResourcesGuide from './ResourcesGuide'
import SessionStore from '../../auth/src/stores/SessionStore'
import StylishHatAndTails from '../StylishHatAndTails'

import {hasWhiteLabelCobranding, hasEverplansCobranding} from '../../lib/cobrandingTools'

import './header.scss'
import GlobalAnnouncementBanner from '../../dashboard/src/components/feedback/GlobalAnnouncementBanner'

export default class HeaderController extends React.Component {
  constructor() {
    super()

    this.brandingStore = Container.getStore('config')
    this.headerStore = Container.getStore('headerFlavor')

    this.state = {
      flavor: this.headerStore.getState().data.flavor,
      currentUser: SessionStore.getState().currentUser || {},
      cobranding: this.brandingStore.getState().data.cobranding
    }

    this.flavor = this.flavor.bind(this)
    this.updateDisplayName = this.updateDisplayName.bind(this)
    this.updateFlavor = this.updateFlavor.bind(this)
  }

  componentDidMount() {
    SessionStore.addChangeListener(this.updateDisplayName)
    this.headerStore.addChangeListener(this.updateFlavor)
    this.updateFlavor() // This makes sure the flavor state is being set once the store has data in it after refreshing the page - TK
  }

  componentWillUnmount() {
    SessionStore.removeChangeListener(this.updateDisplayName)
    this.headerStore.removeChangeListener(this.updateFlavor)
  }

  updateDisplayName() {
    var session = SessionStore.getState()
    if (session !== null)
      this.setState({currentUser: session.currentUser})
  }

  updateFlavor() { this.setState({flavor: this.headerStore.getState().data.flavor}) }

  flavor() { return window.location.pathname.match('document-bundles/*') ? 'guest-user' : this.props.flavor || this.state.flavor }

  isLogInOrSignUpFlavor() {
    return this.flavor() === 'login' || this.flavor() === 'sign-up'
  }

  render () {
    return (
      <React.Fragment>
        <GlobalAnnouncementBanner className={classnames({'with-login-or-sign-up-flavor-header': this.isLogInOrSignUpFlavor()})} />
        <Header
          cobranding={this.state.cobranding}
          flavor={this.flavor()}
          currentUser={this.state.currentUser}
        />
      </React.Fragment>
    )
  }
}

HeaderController.propTypes = {
  flavor: PropTypes.oneOf(['guest-user', 'login', 'sign-up', 'transactional', 'app'])
}


export class Header extends React.Component {
  classNames() {
    var flavor = this.props.flavor || 'transactional'
    return classnames({
      cobranded: this.props.cobranding && this.props.cobranding.enabled,
      [`${flavor}-flavor`]: true
    })
  }

  isLeftAligned() { return ['login', 'guest-user'].includes(this.props.flavor) }

  renderLogo() {
    if (hasEverplansCobranding(this.props.cobranding))
      return <EverplansBrandedCobranding cobranding={this.props.cobranding} leftAligned={this.isLeftAligned()} />
    if (hasWhiteLabelCobranding(this.props.cobranding))
      return <PoweredBy {...this.props.cobranding} />
    else
      return <Logo />
  }

  render() {
    return (
      <div className='hat-and-tails header-one'>
        <Dev.Title title={this.props.currentUser ? this.props.currentUser.id : null} />
        <StylishHatAndTails cobranding={this.props.cobranding} />
        <header className={this.classNames()}>
          <nav>
            <div className='logo-container'>
              <div className='logo'>
                <a href={this.props.currentUser ? API_HOST : CONTENT_HOST}>
                  {this.renderLogo()}
                </a>
                {
                  this.props.flavor === 'app' &&
                  !$.isEmptyObject(this.props.currentUser) &&
                  (
                    <span className='mini-login'>
                      <AccountDropdown
                        currentUser={this.props.currentUser}
                        flavor={this.props.flavor}
                        cobranding={this.props.cobranding}
                      />
                    </span>
                  )
                }
              </div>
            </div>
            <div className='cobranded-divider' />
            <FlavorMenu flavor={this.props.flavor} currentUser={this.props.currentUser} cobranding={this.props.cobranding} />
          </nav>
        </header>
      </div>
    )
  }
}

Header.propTypes = {
  cobranding: PropTypes.object,
  currentUser: PropTypes.object,
  flavor: PropTypes.oneOf(['quick-plan', 'guest-user', 'login', 'sign-up', 'transactional', 'app'])
}

Header.defaultProps = {
  currentUser: {},
  flavor: 'app'
}

const FlavorMenu = props => {
  switch (props.flavor) {
    case 'login':
      return <LoginMenu {...props} />
    case 'app':
      return <Menu {...props} />
    case 'guest-user':
      return <GuestUserMenu {...props} />
    case 'sign-up':
      return <SignUpMenu />
    case 'transactional':
    default:
      return <TransactionalMenu {...props} />
  }
}

FlavorMenu.propTypes = {
  flavor: PropTypes.oneOf(['guest-user', 'login', 'sign-up', 'transactional', 'app'])
}

const LoginMenu = () => (
  <div className='menu'>
    <div className='prompt'>Already have an account?</div>
    <Links.LogIn />
  </div>
)

const GuestUserMenu = () => (
  <div className='menu'>
    <div className='everplans-motto'>A new way to plan for the future.</div>
  </div>
)

const SignUpMenu = () => (
  <div className='menu'>
    <div className='prompt'>Need an account?</div>
    <Links.SignUp />
  </div>
)

const TransactionalMenu = () => <span />

Header.defaultProps = {cobranding: {enabled: false}}

const Menu = props => {
  if (!props.currentUser)
    return <span />

  var user = props.currentUser ? props.currentUser : {}
  return (
    <div className='menu-container'>
      <div className='menu'>
        <Links.MyPersonalEverplan currentUser={props.currentUser} />
        <ResourcesGuide />
        <AccountDropdown currentUser={user} cobranding={props.cobranding} />
      </div>
    </div>
  )
}

Menu.propTypes = {
  cobranding: PropTypes.shape({
    enabled: PropTypes.bool
  }),
  currentUser: PropTypes.object
}
