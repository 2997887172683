const utils = {
  paintSVGForground(className, foregroundColor) {
    if (typeof foregroundColor === 'undefined') return

    var elements = document.getElementsByClassName(className)
    for (var e = 0; e < elements.length; e++) {
      var element = elements[e]
      var logoPaths = element !== null ? element.getElementsByTagName('path') : []
      for (var i = 0; i < logoPaths.length; i++) {
        logoPaths[i].style.fill = foregroundColor
      }
    }
  }
}

export default utils
