import {Component} from 'react'
import ReactDOM from 'react-dom'
import Button from '../Button'
import {DropDownItem, DropDownMenu} from './DropDownItem'

import './header.scss'


const ResourcesGuide = () => (
  <DropDownItem className='resources-menu' title='Resources'>
    <ResourcesDropdown />
  </DropDownItem>
)


class ResourcesDropdown extends DropDownMenu {
  render () {
    return (
      <span>
        <DropDownMenu className='resources-dropdown'>
          <div className='resource-box'>
            <div className='resource-col'>
              <p className='heading'>Planning Categories</p>
              <div className='categories'>
                <ul>
                  <li><a href='https://www.everplans.com/advance-directive'>Advance Directives</a></li>
                  <li><a href='https://www.everplans.com/digital-estate'>Digital Estate</a></li>
                  <li><a href='https://www.everplans.com/eldercare'>Eldercare</a></li>
                  <li><a href='https://www.everplans.com//important-documents'>Important Documents</a></li>
                  <li><a href='https://www.everplans.com/life-insurance'>Life Insurance</a></li>
                </ul>
                <ul>
                  <li><a href='https://www.everplans.com/trusts'>Trusts</a></li>
                  <li><a href='https://www.everplans.com/wills'>Wills</a></li>
                  <li><a href='https://www.everplans.com/dealing-with-death'>Dealing With Death</a></li>
                  <li><a href='https://www.everplans.com/funeral'>Funeral</a></li>
                  <li><a href='https://www.everplans.com/power-of-attorney'>Power of Attorney</a></li>
                  <li><a href='https://www.everplans.com/topics'>View All &raquo;</a></li>
                </ul>
              </div>
            </div>
            <div className='resource-col'>
              <p className='heading'>Resources</p>
              <ul>
                <li><a href='https://www.everplans.com/blog'>Blog</a></li>
                <li><a href='https://www.everplans.com/state-by-state-guides'>State-by-State Guides</a></li>
                <li><a href='https://www.everplans.com/checklists'>Checklists</a></li>
                <li><a href='https://www.everplans.com/how-to-get-started'>Getting Started With Planning</a></li>
                <li><a href='https://www.everplans.com/guides/funeral-guides'>Local Funeral Guides</a></li>
                <li><a href='https://www.everplans.com/funeral-update'>Funeral Update</a></li>
              </ul>
            </div>
          </div>
          <SearchResources />
        </DropDownMenu>
      </span>
    )
  }
}


class SearchResources extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).addEventListener('click', this.click)
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this).removeEventListener('click', this.click)
  }

  click(event) {
    if (event)
      event.stopPropagation()
  }

  render() {
    return (
      <form className='search' action={CONTENT_HOST} method='post'>
        <input
          autoFocus
          maxLength='128'
          name='search_block_form'
          placeholder='Enter a search term'
          size='15'
          type='text'
        />
        <input name='form_id' type='hidden' value='search_block_form' />
        <Button type='submit' className='btn-search'>SEARCH</Button>
      </form>
    )
  }
}

export default ResourcesGuide
