import Payment from 'payment'

import {domainEmailRegex} from '../../lib/emailTools'
import {removeDashesAndCapitalize} from '../../lib/tools'
import Validate from '../../lib/Validate'

// There's some weird binding bizness we have to account for.
const template = (name, data, constraints) => {
  const errors = {name, errors: Validate(data, constraints, {format: 'flat'})}

  if (errors.errors) return errors
}

const validators = {
  template,
  birthdateValidator(name, data) {
    const constraints = {
      [`${name}`]: {
        // `presence: true` does not check for `Invalid Date` and can cause multiple errors
        // ensures that all DateInputs will throw the same errors and checks for age
        // currently the backup only works for birthdates, if implementing other date types pass an object instead of true --NP
        datetimePresenceBackup: true
      }
    }
    return template(name, data, constraints)
  },
  emailDomainValidator(name, data, domainNames) {
    const constraints = {
      [name]: {
        format: {
          pattern: domainEmailRegex(domainNames && domainNames.split(',').map(domain => domain.trim()).join('|')),
          message: `must be one of the following domain(s): ${domainNames}.`
        },
        presence: {message: "^Email can't be blank."}
      }
    }

    if (domainNames)
      return template(name, data, constraints)
    else
      return this.emailValidator(name, data)
  },
  emailValidator(name, data) {
    const constraints = {}
    constraints[name] = {
      email: {message: '^Email is not valid.'},
      presence: {message: "^Email can't be blank."}
    }

    if (data.email)
      data.email = data.email.trim()
    else if (data.email_address)
      data.email_address = data.email_address.trim()
    return template(name, data, constraints)
  },
  emailNotRequiredValidator(name = 'email', data) {
    const constraints = {}
    constraints[name] = {email: {message: '^Email is not valid.'}}
    return template(name, data, constraints)
  },
  radioListValidator(name, data) {
    const constraints = {}
    constraints[name] = {presence: {message: '^Please make a selection above.'}}
    return template(name, data, constraints)
  },
  positiveInteger(name, data) {
    const constraints = {}
    constraints[name] = {
      numericality: {
        greaterThanOrEqualTo: 0,
        message: '^Must be zero or positive number.',
        onlyInteger: true
      },
      presence: true
    }
    return template(name, data, constraints)
  },
  required: (name, data) => {
    const constraints = {}

    constraints[name] = {
      presence: {
        message: `^${removeDashesAndCapitalize(name)} can't be blank.`
      }
    }

    return template(name, data, constraints)
  },
  urlValidator(name, data) {
    const constraints = {}
    constraints[name] = {
      format: {
        message: '^URL must start with http:// or https://.',
        pattern: /^https?:\/\/.+\..+/i
      }
    }
    return template(name, data, constraints)
  },
  creditCardNumberValidator(name, data) {
    const validation = Payment.fns.validateCardNumber(data.creditCardNumber)
    const errors = {name, errors: validation ? null : 'Please enter a valid card number.'}

    if (errors.errors) return errors
  },
  creditCardDateValidator(name, data) {
    const validation = Payment.fns.validateCardExpiry(data.expirationDate)
    const errors = {name, errors: validation ? null : 'Please enter a valid expiration date.'}

    if (errors.errors) return errors
  },
  creditCardCVCValidator(name, data) {
    const validation = Payment.fns.validateCardCVC(data.cvc)
    const errors = {name, errors: validation ? null : 'Please enter a valid CVC number.'}

    if (errors.errors) return errors
  },
  creditCardDateAndCVCValidator(name, data) {
    const errors = []

    if (!Payment.fns.validateCardExpiry(data.expirationDate)) errors.push('Please enter a valid expiration date.')
    if (!Payment.fns.validateCardCVC(data.cvc)) errors.push('Please enter a valid CVC number.')
    if (errors && errors.length) return {name, errors}
  }
}

export default validators
