import classnames from 'classnames'
import PropTypes from 'prop-types'

import PlusIcon from '../../../../shared_components/core/svg_icons/icons/PlusIcon'

import {noop} from '../../../../lib/tools'

import './buttons.scss'


// Basic buttons:
export const Button = ({processing, ...otherProps}) => {
  const handleClick = event => {
    if (event)
      event.preventDefault()

    if (otherProps.onClick)
      otherProps.onClick()
  }

  return (
    <button
      {...otherProps}
      className={classnames('core', otherProps.className, {processing})}
      onClick={processing ? noop : handleClick}
      type={otherProps.type ? otherProps.type : 'button'}
    />
  )
}

Button.defaultProps = {
  onClick: noop,
  processing: false
}

Button.propTypes = {
  onClick: PropTypes.func,
  processing: PropTypes.bool
}


export const PlusIconButton = ({children, ...otherProps}) => (
  <Button {...otherProps}>
    {children}
    <PlusIcon />
  </Button>
)


// More-specific buttons:
export const Accept = props => <Button {...props} className={classnames('accept', props.className)} />
Accept.defaultProps = {children: 'Accept'}

export const Add = props => <PlusIconButton {...props} className={classnames('add', props.className)} />
Add.defaultProps = {children: 'Add'}

export const Cancel = props => <Button {...props} className={classnames('cancel', props.className)} />
Cancel.defaultProps = {children: 'Cancel'}

/** @class MultilineButton
 *
 * Flavor of `Button` that allows for multiple lines of text and grows to accomodate them.
 * The `children` should be an array of strings where each will be wrapped in a `div` to force
 * the necessary line breaks.
 */
export const MultilineButton = props => (
  <Button
    {...props}
    children={props.children.map((child, index) => <div key={`${child}${index}`}>{child}</div>)}
    className={classnames('multiline', props.className)}
  />
)
MultilineButton.defaultProps = {children: ['Click', 'Me']}

export const Remove = props => <Button {...props} className={classnames('remove', props.className)} />
Remove.defaultProps = {children: 'Remove'}
