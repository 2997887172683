import Header from '../shared_components/header/Header'

import ConfigActions from '../shared_actions/ConfigActions'
import Container from './Container'
import featureFlags from './FeatureFlags'
import jsonStatham from './jsonStatham'
import storePrototype from '../shared_components/StorePrototype'
import {stripLeadingSlash} from './urlTools'
import urlParse from 'url-parse'


export default {
  booted: false,
  bootConfig(bindRoutes) {
    // Warning: this 'action' is odd for reasons described in the comment below. Additionally it is similar to ConfigActions.fetchConfig,
    // but this duplication is intentional as each version is used in different contexts. Comment below has more info. --BLR
    jsonStatham.get(this.configApiPath()).then(
      data => {
        featureFlags.init(data.config.feature_flags)
        // Using receiveData directly goes against the standard Flux lifecycle pattern and doesn't match our normal actions, but this
        // is fired before the app is fully booted, so we want to be cut and dry, and not wait for a callback. --BJK && BLR
        Container.registerStore('config', storePrototype(ConfigActions.Types.GOT_CONFIG)).receiveData(data.config)
        bindRoutes()
        this.booted = true
      }
    )
  },
  setAppName(location) { Header.appName = stripLeadingSlash(location.pathname).split('/')[0] },
  waitForBoot(callback) {
    const check = setInterval(
      () => {
        if (this.booted) {
          callback()
          clearInterval(check)
        }
      },
      100
    )
  },
  configApiPath() {
    const location = window.location
    const urlQuery = urlParse(location.hash ? location.hash.substr(1) : location.href, true).query

    return `/config${urlQuery.firmcb ? `/${urlQuery.firmcb}` : ''}`
  }
}
