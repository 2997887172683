import {List} from 'immutable'

import {cleanResponse} from '../redux/apiHelper'

import {RESHAPE_CATEGORIES} from '../actions/corpMaxActionTypes'

const categoriesReducer = (state = List(), action) => {
  switch (action.type) {
    case RESHAPE_CATEGORIES:
      return cleanResponse(action.payload)
    default:
      return state
  }
}

export default categoriesReducer
