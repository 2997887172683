export const legacyThirdPerson = type => {
  switch (type) {
    case 'object':
      return 'them'
    case 'subject':
      return 'they'
    case 'modifying_possessive':
      return 'their'
    case 'standalone_possessive':
      return 'theirs'
    case 'reflexive':
      return 'themself'
    default:
      break
  }
}


export const thirdPerson = type => {
  switch (type) {
    case 'object':
      return 'them'
    case 'subject':
      return 'they'
    case 'modifying-possessive':
      return 'their'
    case 'standalone-possessive':
      return 'theirs'
    case 'reflexive':
      return 'themself'
    default:
      break
  }
}

export const personalPronoun = (pronoun, type) => (
  pronoun ? pronoun.get(type) : thirdPerson(type)
)

export const legacyPersonalPronoun = (pronoun, type) => (
  pronoun ? pronoun[type] : legacyThirdPerson(type)
)

export const linkingVerb = size => (size > 1 ? 'were' : 'was')

// This is a copy of the rich Everplan Pronoun helper method from Rails
export const everplanPronouns = isHousehold => {
  if (isHousehold)
    return ['We', 'We are', 'our', "We'd", 'us', 'we', "We've", 'lives', "we'd", "We're"]

  return ['I', "I'm", 'my', "I'd", 'me', 'I', "I've", 'life', "I'd", "I'm"]
}

export const presentPossessionVerb = ({plural}) => (plural ? 'have' : 'has')

export const presentLinkingVerb = ({plural}) => (plural ? 'are' : 'is')

export const everplanOwnerPronoun = ({isHousehold, owners}) => (
  isHousehold ? null : owners.getIn([0, 'pronoun'])
)

export const currentUserPronoun = ({isHousehold, profile}) => (
  isHousehold ? null : profile.get('pronoun')
)

export const pluralPresentPossession = ({pronoun}) => (
  presentPossessionVerb({plural: !pronoun || pronoun.get('plural-conjugation')})
)
