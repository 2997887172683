import extra_storage from './extra_storage'
import { jsonStatham } from 'fluxxed_up'

class EpAjaxAdaptor {
  serverBase() { return API_HOST }
  pathRoot() { return '/api/v1' }
  defaultHeaders() {
    var headers = {}
    const authToken = extra_storage.getItem('authToken')
    if (authToken)
      headers.Authorization = `Token token=${authToken}`
    return headers
  }
}

jsonStatham.setAdaptor(new EpAjaxAdaptor())

export default jsonStatham
