import Select from './Select'
import Validators from './Validators'
import Wrapper from './Wrapper'
import CompoundFormComponent from './CompoundFormComponent'
import PronounsTooltip from '../core/tooltips/PronounsTooltip'


export default class PronounsInput extends CompoundFormComponent {
  defaultValue() {
    if (this.props.pronoun)
      // For the non-binary option the legacy_gender is empty, and this just seemed like the cleanest way to figure out the
      // default value, since the placeholder option will have an empty key and so couldn't just use the legacy_gender.
      return this.props.pronoun.legacy_gender || 'nb'


    return ''
  }

  getChildContext() {
    return {setFormValue: this.setFormValue.bind(this), errors: this.context.errors}
  }

  setFormValue(key, value) {
    // This is needed, otherwise the form thinks the selection is empty and won't allow the submission of the form
    this.context.setFormValue(key, value)

    // This is needed since we are sending back to the API the gender not pronoun, but the name of the field has to be `pronoun`
    // in order to show the error text properly.
    this.context.setFormValue('gender', value === 'nb' ? null : value)
  }

  render() {
    return (
      <Wrapper label={this.props.label} errors={this.errors('pronouns')}>
        <span className='pronouns-container'>
          <Select
            className='pronouns'
            defaultValue={this.defaultValue()}
            list={[{key: 'f', value: 'She/Her'}, {key: 'm', value: 'He/Him'}, {key: 'nb', value: 'They/Them'}]}
            name='pronouns'
            validator={Validators.required}
          />
          <PronounsTooltip />
        </span>
      </Wrapper>
    )
  }
}

PronounsInput.defaultProps = {
  validator: (name, data) => {
    if (!data.pronouns)
      return {name: 'pronouns', errors: ['Please select one.']}
  },
  label: 'Choose one'
}
