import React from 'react'

var Errors = props => {
  var errorList = typeof props.errors === 'string' ? [props.errors] : props.errors

  if (typeof props.errors === 'undefined')
    return <span />

  return <ErrorList errors={errorList.map(error => <div key={error}>{error}</div>)} />
}

const ErrorList = props => <div className='form-errors'>{props.errors}</div>

export default Errors
