import {Map} from 'immutable'

import {cleanResponse} from '../redux/apiHelper'
import {listMappingDictionary} from '../../../../../lib/plan_data/item'

import {RESHAPE_LIST_MAPPINGS} from '../actions/corpMaxActionTypes'

const listMappingsReducer = (state = Map(), action) => {
  switch (action.type) {
    case RESHAPE_LIST_MAPPINGS:
      return listMappingDictionary(cleanResponse(action.payload))
    default:
      return state
  }
}

export default listMappingsReducer
