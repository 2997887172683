import KeyMirror from 'keymirror'

import AppDispatcher from '../../../lib/ep-dispatcher'

const UtilActions = {
  Types: KeyMirror({
    DISMISS_FLASH: null
  }),

  dismissFlash() {
    AppDispatcher.dispatch({actionType: UtilActions.Types.DISMISS_FLASH})
  }
}

export default UtilActions
