import React from 'react'
import {hashHistory} from 'react-router'

import {stripLeadingSlashHash} from './urlTools'
import TestPrep from './TestPrep'


const routerUtils = {
  childrenWithProps(children, props) {
    if (children)
      return React.cloneElement(children, props)

    return null
  },

  goBack() { hashHistory.goBack() },

  goForward() { hashHistory.goForward() },

  push(hashLocation) {
    // Obscure the lowerlevel implementation.
    if (typeof hashLocation === 'string') {
      hashHistory.push(stripLeadingSlashHash(hashLocation))
    } else {
      if (hashLocation.pathname)
        hashLocation.pathname = stripLeadingSlashHash(hashLocation.pathname)
      hashHistory.push(hashLocation)
    }
  },

  replace(hashLocation) { hashHistory.replace(hashLocation) },

  setLocation(location) { window.location = location },

  /**
   * Constructs a new hash location with an added/updated query while leaving all other information alone.
   *
   * @param {object} hashLocation - the current history location
   * @param {string} queryKey - the query key for which the value should be added or modified
   * @param {string} queryValue - the value of the added or modified query
   *
   * @returns {object} the updated history location including the new query.
   */
  constructLocationWithNewQuery(hashLocation, queryKey, queryValue) {
    return {
      ...hashLocation,
      query: {
        ...hashLocation.query,
        [queryKey]: queryValue
      }
    }
  },

  /**
   * Constructs a new hash location with a single query removed completely.
   *
   * @param {object} hashLocation - the current history location
   * @param {string} queryKey - the query key to be removed
   *
   * @returns {object} the updated history location with the query removed completely.
   */
  constructLocationWithoutQuery(hashLocation, queryKey) {
    // This destructuring fully removes the `queryKey`` and its value rather than just setting it to an
    // empty string. `omit` is intentionally unused.
    const {[queryKey]: omit, ...otherQueries} = hashLocation.query // eslint-disable-line no-unused-vars

    return {
      ...hashLocation,
      query: otherQueries
    }
  },

  /** Navigates to a new history location with an added/modified query. */
  addQuery({hashLocation, queryKey, queryValue}) {
    hashHistory.push(this.constructLocationWithNewQuery(hashLocation, queryKey, queryValue))
  },

  /** Navigates to a new history location with a query removed. */
  removeQuery({hashLocation, queryKey}) {
    hashHistory.push(this.constructLocationWithoutQuery(hashLocation, queryKey))
  }
}

export default {
  ...TestPrep,
  ...routerUtils
}
