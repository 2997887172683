import classNames from 'classnames'
import {useContext} from 'react'
import {Text} from '../blocks/Texts'
import WarningIconCircle from '../../../assets/WarningIconCircle'
import {GlobalAnnouncementContext} from '../../../../shared_components/ApplicationContainer'

import './globalAnnouncementBanner.scss'

const GlobalAnnouncementBanner = ({className}) => {
  const {globalAnnouncementText} = useContext(GlobalAnnouncementContext)

  if (!globalAnnouncementText)
    return null

  return (
    <div className={classNames('global-announcement-banner', className)}>
      <div className='global-announcement-banner-content'>
        <WarningIconCircle className='warning' alt='warning' />
        <Text className='message'>
          {globalAnnouncementText}
        </Text>
      </div>
    </div>
  )
}

export default GlobalAnnouncementBanner
