import PropTypes from 'prop-types'
import React from 'react'
import Header from './Header'
import {If, Else} from '../deprecated/IfFlag'
import {DropDownItem, DropDownMenu} from './DropDownItem'
import * as Links from '../Links'
import cobrandUtils from './cobrandUtils'

import './header.scss'

export default class AccountDropdown extends React.Component {
  render() {
    return (
      <DropDownItem className='account' title={<Name currentUser={this.props.currentUser} />} icon={<Icon {...this.props.cobranding} />}>
        <DropDownMenu className='account-box'>
          <ul className='account-dropdown' >
            <li><Links.AccountSettings /></li>
            <If flag={Header.appName === 'pro'}>
              <If flag={this.props.currentUser.firm_user}>
                <li><Links.ProResourceCenter /></li>
              </If>
              <If flag={this.props.currentUser.account_manager}>
                <li><a href='#/pro/firm-admin/all-firms'>All Firms</a></li>
              </If>
            </If>
            <li><Links.Help advisor={this.props.currentUser.firm_user} /></li>
            <li><Links.SignOut /></li>
            <If flag={this.props.currentUser.firm_user} >
              <hr className='navLine' />
              <If flag={Header.appName === 'pro'}>
                <li><Links.MyPersonalEverplan currentUser={this.props.currentUser} /></li>
              </If>
              <Else flag={Header.appName === 'pro'}>
                <li><Links.MyProDashboard onClick={this.props.closer} /></li>
              </Else>
            </If>
          </ul>
        </DropDownMenu>
      </DropDownItem>
    )
  }
}

AccountDropdown.propTypes = {flavor: PropTypes.string}

class Icon extends React.Component {
  componentDidMount() {
    if (!this.props.enabled) return

    cobrandUtils.paintSVGForground('user-guy', this.props.foreground_color)
  }

  render() {
    return (
      <svg id='user-guy' className='user-guy' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'>
        <title>Default User Icon</title>
        <g id='5a159dc8-866c-4246-a4d3-1e67f38a71d2' data-name='Summary-View'>
          <g id='b3d8520e-9c73-4912-8aea-fcdaed3dee11' data-name='Desktop'>
            <path id='32efc1e7-0fde-4231-b81d-c110bb03af3b' data-name='Imported-Layers' d='M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0h0Zm0,1.2a12.8,12.8,0,0,1,9.9,21l-6.2-2.1c-0.2,0-.2-0.1-0.2-0.7a3.8,3.8,0,0,1,.4-1.6,8,8,0,0,0,.6-2.1,4.8,4.8,0,0,0,.9-2.2,2.9,2.9,0,0,0,0-1.9h0a12.1,12.1,0,0,1,.2-2.6,4.8,4.8,0,0,0-1-3.5,5.2,5.2,0,0,0-3.9-1.9H13.5A5.1,5.1,0,0,0,9.6,5.4a4.8,4.8,0,0,0-1,3.5,12.1,12.1,0,0,1,.2,2.6h0a2.9,2.9,0,0,0,0,1.9,4.8,4.8,0,0,0,.9,2.2,7.9,7.9,0,0,0,.6,2.1,3.8,3.8,0,0,1,.3,1.6c0,0.7,0,.7-0.2.7-1.9.6-5,1.7-6.2,2.2A12.8,12.8,0,0,1,14,1.2h0Zm-9,22,5.7-2a1.6,1.6,0,0,0,1-1.8,5,5,0,0,0-.4-2.1,6.6,6.6,0,0,1-.5-1.9,0.6,0.6,0,0,0-.2-0.4,3.5,3.5,0,0,1-.7-1.7,1.9,1.9,0,0,1,0-1.2V11.8a11.4,11.4,0,0,0-.2-3.1,3.6,3.6,0,0,1,.8-2.5,4,4,0,0,1,3-1.4h1.1a4,4,0,0,1,3.1,1.4,3.6,3.6,0,0,1,.8,2.5,11.5,11.5,0,0,0-.2,3.1V12a1.9,1.9,0,0,1,0,1.2,3.5,3.5,0,0,1-.7,1.7,0.6,0.6,0,0,0-.2.4,6.6,6.6,0,0,1-.5,1.9,4.9,4.9,0,0,0-.6,2.1,1.6,1.6,0,0,0,1,1.8L23,23.1a12.8,12.8,0,0,1-18,.1H5Z' fill='#fff' />
          </g>
        </g>
      </svg>
    )
  }
}

const Name = props => {
  if (!props.currentUser.name) return <span />

  return (
    <span>
      <span className='full-name'>{props.currentUser.display_name}</span>
      <span className='mini-name'>{props.currentUser.name.split(' ').map(word => word[0]).join('')}</span>
    </span>)
}
