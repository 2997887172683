import classnames from 'classnames'
import React from 'react'

import InputBase from './InputBase'
import Input from './Input'
import Wrapper from './Wrapper'

export default class RadioList extends InputBase {
  render() {
    if (!this.props.list)
      return null

    return (
      <Wrapper className='form-radio-list' name={this.props.name} label={this.props.label} errors={this.errors()}>
        <ul className='radio-list'>
          {
            this.props.list.map(item => (
              <li className={classnames('radio-item', {'disabled-radio-item': item.isDisabled})} key={item.key}>
                <label><InputElement {...this.props} disabled={item.isDisabled} item={item} />{item.value}</label>
              </li>
            ))
          }
        </ul>
      </Wrapper>
    )
  }
}


var InputElement = props => (
  <Input
    defaultChecked={props.defaultValue === props.item.key}
    key={`${props.name}-${props.item.value}`}
    name={props.name}
    onChange={props.onChange}
    type='radio'
    disabled={props.disabled}
    value={props.item.key || props.value}
    wrap={false}
  />
)
