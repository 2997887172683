import PropTypes from 'prop-types'
import React from 'react'
import {If} from '../deprecated/IfFlag'
import classnames from 'classnames'

export class DropDownItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {open: props.open}

    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.openDropdown = this.openDropdown.bind(this)
    this.closeDropdown = this.closeDropdown.bind(this)
  }

  toggleDropdown(event) {
    if (!this.state.open)
      this.openDropdown(event)
    else
      this.closeDropdown(event)
  }

  openDropdown(event) {
    event.stopPropagation()
    window.addEventListener('blur', this.closeDropdown)
    document.addEventListener('click', this.closeDropdown)
    document.addEventListener('touchenter', this.closeDropdown)
    this.setState({open: true})
  }

  closeDropdown(event) {
    event.stopPropagation()
    window.removeEventListener('blur', this.closeDropdown)
    document.removeEventListener('click', this.closeDropdown)
    document.removeEventListener('touchenter', this.closeDropdown)
    this.setState({open: false})
  }

  getChildContext() {
    return {closer: this.closeDropdown}
  }

  render() {
    return (
      <div className={classnames('menu-item', {[`${this.props.className}`]: this.props.className})}>
        <span onClick={this.toggleDropdown}>{this.props.icon}</span>
        <a onClick={this.toggleDropdown}>
          <span>{this.props.title}</span>
          <i className='fa fa-white fa-caret-down' />
        </a>
        <If flag={this.state.open}>{this.props.children}</If>
      </div>
    )
  }
}


DropDownItem.childContextTypes = {closer: PropTypes.func}

export class DropDownMenu extends React.Component {
  render() {
    return (
      <div className={`dropdown-menu-ho ${this.props.className}`} onClick={this.context.closer}>
        <div className='dropdown-box'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

DropDownMenu.contextTypes = {closer: PropTypes.func}
