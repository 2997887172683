import AppDispatcher from '../../../lib/ep-dispatcher'
import KeyMirror from 'keymirror'

const SignInActions = {
  Types: KeyMirror({
    SIGN_IN: null,
    SIGN_IN_SECONDARY: null,
    SIGN_IN_WITH_CODE: null,
    SUBMIT_BACKUP_CODE: null,
    SIGN_OUT: null,
    CLEAR_SIGN_IN_ERRORS: null
  }),

  signIn(email, password, nextRoute, nextPath) {
    AppDispatcher.dispatch({
      actionType: SignInActions.Types.SIGN_IN,
      email: email.toLowerCase().trim(),
      password,
      nextRoute,
      nextPath
    })
  },

  signOut() {
    AppDispatcher.dispatch({
      actionType: SignInActions.Types.SIGN_OUT
    })
  },

  signInSecondary(email, password) {
    AppDispatcher.dispatch({
      actionType: SignInActions.Types.SIGN_IN_SECONDARY,
      email: email.toLowerCase().trim(),
      password
    })
  },

  signInWithCode(data) {
    AppDispatcher.dispatch({
      actionType: SignInActions.Types.SIGN_IN_WITH_CODE,
      data
    })
  },

  submitBackupCode(email, password, code) {
    AppDispatcher.dispatch({
      actionType: SignInActions.Types.SUBMIT_BACKUP_CODE,
      email: email.toLowerCase().trim(),
      password,
      backupCode: code
    })
  },

  clearSignInErrors() {
    AppDispatcher.dispatch({
      actionType: SignInActions.Types.CLEAR_SIGN_IN_ERRORS
    })
  }
}

export default SignInActions
