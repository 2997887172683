import React from 'react'
import InputBase from './InputBase'
import Wrapper from './Wrapper'

export default class Input extends InputBase {
  constructor(props) {
    super(props)
    this.setInitialState(props) // weird workaround for ie shallow copy: http://babeljs.io/docs/plugins/transform-proto-to-assign/
  }
  render() {
    if (this.props.wrap) {
      return (
        <Wrapper {...this.props} errors={this.errors()}>
          <InputElement {...this.props} value={this.state.formValue} onChange={this.onFormElementChange.bind(this)} />
        </Wrapper>
      )
    } else {
      return <InputElement {...this.props} value={this.state.formValue} onChange={this.onFormElementChange.bind(this)} />
    }
  }
}

const InputElement = ({wrap, ...otherProps}) => (
  <input {...otherProps} type={otherProps.type ? otherProps.type : 'text'} />
)

Input.defaultProps = {wrap: true}
