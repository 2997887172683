import { apiV2Config, mergeConfigs } from './apiHelpers'
import jsonBourne from './jsonBourne'


const apiV2 = config => jsonBourne(apiV2Config(config))

const DELETE = config => apiV2(mergeConfigs(config, {method: 'delete'}))

const GET = config => apiV2(mergeConfigs(config, {method: 'get'}))

const PATCH = config => apiV2(mergeConfigs(config, {method: 'patch'}))

const POST = config => apiV2(mergeConfigs(config, {method: 'post'}))

const PUT = config => apiV2(mergeConfigs(config, {method: 'put'}))

export default {
  DELETE,
  GET,
  PATCH,
  POST,
  PUT
}
