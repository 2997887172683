import classnames from 'classnames'
import PropTypes from 'prop-types'


const criterionRowIconClassName = ({isValid}) => classnames('fa', {'fa-check': isValid, 'fa-times': !isValid})


const CriterionRow = ({criterion}) => (
  <li className={criterion.isValid ? '✓' : '×'}>
    <div className='symbol-wrap'>
      <i className={criterionRowIconClassName(criterion)} />
    </div>
    {criterion.description}
  </li>
)

CriterionRow.propTypes = {
  criterion: PropTypes.shape({
    description: PropTypes.string,
    isValid: PropTypes.bool
  })
}


const PasswordFlyout = ({criteria, header}) => (
  <section className='password-flyout'>
    <section>
      <section className='header'>
        {header}
      </section>
      <ul className='flyoutContent'>
        {
          criteria.map(
            criterion => <CriterionRow criterion={criterion} key={criterion.description} />
          )
        }
      </ul>
    </section>
  </section>
)

PasswordFlyout.propTypes = {
  criteria: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string
    })
  ),
  header: PropTypes.string
}

export default PasswordFlyout
