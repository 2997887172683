import {setAxiosConfig} from 'redux-json-api'

/**
 * Initializes the axios configuration so that APIv2 calls can be executed with the correct headers.
 *
 * @param {store} instance of the redux store passed that has already initialized.
 * @param {authToken} an authToken collected from local storage.
 *
 * @returns {Object} header and baseURL configuration object formatted for axios.
 *
 */
export const axiosConfiguration = authToken => {
  return {
    baseURL: `${API_HOST}/api/v2`,
    headers: {
      Authorization: `Token token=${authToken}`
    }
  }
}

/**
 * Updates the axios configuration so that APIv2 calls can be executed with the correct headers.
 * This should be used whenever the authToken needs to be reset in the axiosConfig.
 * e.g. any child components mount right after sign-in and need to make an APIv2 request.
 *
 * @param {setAxiosConfiguration} bound setAxiosConfig passed from a component.
 * @param {authToken} an authToken collected from local storage.
 *
 */
export const updateAxiosConfiguration = ({setAxiosConfiguration, authToken}) => {
  setAxiosConfiguration(axiosConfiguration(authToken))
}

/**
 * Initializes the axios configuration so that APIv2 calls can be executed with the correct headers.
 * This should ONLY be used ONCE when the app initially loads to set the configuration using an
 * existing instance of the redux store to dispatch the change.
 *
 * @param {store} instance of the redux store passed that has already initialized.
 * @param {authToken} an authToken collected from local storage.
 *
 */
export const initializeAxiosConfiguration = ({store, authToken}) => {
  store.dispatch(
    setAxiosConfig(axiosConfiguration(authToken))
  )
}
