import AppDispatcher from '../../../lib/ep-dispatcher'
import {EventEmitter} from 'events'
import assign from 'object-assign'
import SignInActions from '../actions/SignInActions'
import UtilActions from '../actions/UtilActions'
import SessionApiResponse from '../web/SessionApiResponse'

const state = {initialized: false}

const SessionStore = assign({}, EventEmitter.prototype, {
  resetData() {
    state.currentUser = null
    state.tfaConfig = null
    state.signingIn = false
    state.signedIn = false
    state.signingInWithBackup = false
    state.signedInWithBackup = false
    state.preAuthenticated = false
    state.preAuthEmail = null
    state.preAuthPassword = null
    state.phoneSentTo = null
    state.signInError = null
    state.primaryPhone = null
    state.secondaryPhone = null
    state.nextRoute = null
    state.nextPath = null
    state.flash = null
    state.lastEnabledAt = null
  },
  getState() { return state },
  signOutUser() {
    this.resetData()
    state.flash = 'For security purposes, you have been logged out.'
    state.signedIn = false
    this.emitChange()
  },
  initialized() { return state.initialized },
  clearErrors() {
    state.signInError = false
    this.emitChange()
  },
  newSignIn(email, password, nextRoute, nextPath) {
    this.resetData()
    state.signedIn = false
    state.signingIn = true
    state.preAuthEmail = email ? email.toLowerCase().trim() : email
    state.preAuthPassword = password
    state.nextRoute = nextRoute
    state.nextPath = nextPath
    this.emitChange()
  },
  updateCurrentUser(user) {
    const backup = state.signingInWithBackup
    const nextRoute = state.nextRoute
    const nextPath = state.nextPath
    this.resetData()
    state.signingInWithBackup = false
    state.signedInWithBackup = backup
    state.nextRoute = nextRoute
    state.nextPath = nextPath
    state.currentUser = user
    state.tfaConfig = user.tfa_config
    state.initialized = true
    state.signedIn = true
    this.emitChange()
  },
  rejectSignIn(error) {
    state.signInError = error
    state.signingIn = false
    state.tfaConfig = null
    state.currentUser = null
    state.signedIn = false
    this.emitChange()
  },
  noCurrentUser() {
    const timedOut = state.currentUser
    if (!state.signingIn) this.resetData()
    if (timedOut) {
      state.signInError = 'Session timed out'
      state.flash = 'Session timed out'
    }
    state.signedIn = false
    state.initialized = true
    this.emitChange()
  },
  tfaCodeSent(primaryPhone, secondaryPhone, phoneSentTo, lastEnabledAt) {
    state.preAuthenticated = true
    state.signingIn = false
    state.primaryPhone = primaryPhone
    state.secondaryPhone = secondaryPhone
    state.phoneSentTo = phoneSentTo
    state.lastEnabledAt = lastEnabledAt
    this.emitChange()
  },
  resetForBackupSignIn() {
    state.signingInWithBackup = true
    state.preAuthenticated = false
    state.phoneSentTo = null
    this.emitChange()
  },
  getObfuscatedPrimaryNumber() { return state.primaryPhone ? state.primaryPhone.obfuscated_number : null },
  getObfuscatedSecondaryNumber() { return state.secondaryPhone ? state.secondaryPhone.obfuscated_number : null },
  addChangeListener(callback) { this.addListener('CHANGE', callback) },
  dismissFlash() {
    state.flash = null
    this.emitChange()
  },
  removeChangeListener(callback) { this.removeListener('CHANGE', callback) },
  emitChange() { this.emit('CHANGE') }
})

SessionStore.resetData()

export default SessionStore


AppDispatcher.register(action => {
  switch (action.actionType) {
    case SessionApiResponse.Types.WEB_USER_SIGNED_IN:
      SessionStore.updateCurrentUser(action.user)
      break
    case SessionApiResponse.Types.WEB_USER_SIGNED_OUT:
      SessionStore.signOutUser()
      break
    case SessionApiResponse.Types.WEB_USER_SIGN_IN_REJECTED:
      SessionStore.rejectSignIn(action.error)
      break
    case SessionApiResponse.Types.WEB_TFA_CODE_SENT:
      SessionStore.tfaCodeSent(action.primaryPhone, action.secondaryPhone, action.phoneSentTo, action.lastEnabledAt)
      break
    case SessionApiResponse.Types.WEB_CURRENT_USER_RECEIVED:
      SessionStore.updateCurrentUser(action.data)
      break
    case SessionApiResponse.Types.WEB_NO_CURRENT_USER:
      SessionStore.noCurrentUser()
      break
    case SignInActions.Types.SIGN_IN_WITH_CODE:
      state.signInError = null
      SessionStore.emitChange()
      break
    case SignInActions.Types.SUBMIT_BACKUP_CODE:
      SessionStore.resetForBackupSignIn()
      break
    case UtilActions.Types.DISMISS_FLASH:
      SessionStore.dismissFlash()
      break
    case SignInActions.Types.CLEAR_SIGN_IN_ERRORS:
      SessionStore.clearErrors()
      break
    case SignInActions.Types.SIGN_IN:
      SessionStore.newSignIn(action.email ? action.email.toLowerCase().trim() : '', action.password, action.nextRoute, action.nextPath)
      break
    default:
      break
  }
})
