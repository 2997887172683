import PropTypes from 'prop-types'

import showInputIcon from '../../shared_assets/v2/unmask-icon.svg'
import hideInputIcon from '../../shared_assets/v2/mask-icon.svg'
import InputBase from './InputBase'
import Wrapper from './Wrapper'

import './passwordInput.scss'

export default class PasswordInput extends InputBase {
  constructor(props) {
    const newProps = {
      ...props,
      showPassword: false
    }

    super(props)

    this.setInitialState(newProps) // weird workaround for ie shallow copy: http://babeljs.io/docs/plugins/transform-proto-to-assign/

    this.onFormElementChange = this.onFormElementChange.bind(this)
    this.toggleMask = this.toggleMask.bind(this)
  }

  toggleMask(event) {
    event.preventDefault()

    this.setState({showPassword: !this.state.showPassword})
  }

  render() {
    const {wrap, ...otherProps} = this.props

    if (wrap) {
      return (
        <div className='password-input'>
          <Wrapper {...otherProps} errors={this.errors()}>
            <InputElement {...otherProps} type={this.state.showPassword ? 'text' : 'password'} value={this.state.formValue} onChange={this.onFormElementChange} />
            <button className='mask-icon-and-text' onClick={this.toggleMask} type='button'>
              <img alt='' className='mask-icon' src={this.state.showPassword ? hideInputIcon : showInputIcon} />
              {this.state.showPassword ? 'Hide' : 'Show'}
            </button>
          </Wrapper>
        </div>
      )
    } else {
      return (
        <div className='password-input'>
          <InputElement {...otherProps} type={this.state.showPassword ? 'text' : 'password'} value={this.state.formValue} onChange={this.onFormElementChange} />
          <button className='mask-icon-and-text' onClick={this.toggleMask} type='button'>
            <img alt='' className='mask-icon' src={this.state.showPassword ? hideInputIcon : showInputIcon} />
            {this.state.showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
      )
    }
  }
}

PasswordInput.propTypes = {wrap: PropTypes.bool}
PasswordInput.defaultProps = {wrap: true}

const InputElement = props => (
  <input {...props} type={props.type ? props.type : 'text'} />
)

InputElement.propTypes = {type: PropTypes.string}
