import {Map} from 'immutable'

import {cleanResponse} from '../redux/apiHelper'
import {kitDictionary} from '../../../../../lib/plan_data/item'

import {RESHAPE_KITS} from '../actions/corpMaxActionTypes'

const kitsReducer = (state = Map({}), action) => {
  switch (action.type) {
    case RESHAPE_KITS:
      return kitDictionary(cleanResponse(action.payload))
    default:
      return state
  }
}

export default kitsReducer
