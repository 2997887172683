/** @class ApplicationErrorBoundary
 *
 * @desc
 * This is a top-level error boundary intended to prevent the complete unmounting of the DOM
 * if any errors that happen below this component are not handled by lower-level error boundaries.
 * It is a heavily-modified version of the 404 page. The approach here is very similar to the
 * first example given in the ReactJS documentation: https://reactjs.org/docs/error-boundaries.html
 *
 */

import {Component} from 'react'
import {fireRollbarError} from '../../lib/sessionTools'

import ApplicationFallback from './ApplicationFallback'


export default class ApplicationErrorBoundary extends Component {
  constructor() {
    super()

    this.state = {
      error: null,
      errorInfo: null,
      hasError: false
    }
  }

  /** Flips the switch to force the app to display the fallback component and prevent DOM unmounting. */
  static getDerivedStateFromError() {
    return {hasError: true}
  }

  /** Sets information about what went wrong in the state for debugging purposes. Event and Rollbar logging will be here soon. */
  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    })
    fireRollbarError(
      'Application Error',
      error
    )
  }

  render() {
    if (this.state.hasError)
      return <ApplicationFallback />
    else
      return this.props.children
  }
}
