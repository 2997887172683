export default {
  originalMethods: {},
  getObjectMethods(object) {
    return Object.getOwnPropertyNames(object).filter(property => typeof object[property] === 'function')
  },
  restore() {
    this.getObjectMethods(this.originalMethods).forEach(method => this[method] = this.originalMethods[method])
    this.originalMethods = {}
  },
  saveMethod(method) { this.originalMethods[method] = this[method] },
  stubbedMethod(methodName, data) {
    if (this.receivedData[methodName])  // Handle multiple calls to the same method within a single test
      this.receivedData[methodName].push(data)
    else
      this.receivedData[methodName] = [data]
  },
  stubForTests() {
    // Don't stub anything if not running tests to prevent any mistakes (BLR):
    if (!__TEST__)
      return

    this.receivedData = {}
    this.getObjectMethods(this).forEach(method => {
      // childrenWithProps is included below so the navigation and history methods on routerUtils can be stubbed out without breaking renders (BLR):
      if (!['getObjectMethods', 'restore', 'saveMethod', 'stubbedMethod', 'stubForTests', 'childrenWithProps'].includes(method)) {
        this.saveMethod(method)
        this[method] = data => this.stubbedMethod(method, data)
      }
    })
  }
}
