import React from 'react'
import cobrandUtils from './cobrandUtils'

export default class PoweredBy extends React.Component {
  componentDidMount() {
    if (!this.props.enabled) return;
    cobrandUtils.paintSVGForground('ep-logo', this.props.foreground_color)
    $('.powered-by-divider').attr('style', `border-color: ${this.props.foreground_color}`)
  }
  render() {
    return (
    <div className='branding'>
        <img src={this.props.header_logo} className='cobranding-logo' />
        <span className='powered-by-divider'/>
        <svg xmlns="http://www.w3.org/2000/svg" id='ep-logo' viewBox="0 0 86.3 28.5" className='powered-by ep-logo'>
          <title>Powered by Everplans</title>
          <path d="M64.3,9.6h0.6a1.6,1.6,0,0,0,1.6-1.1l2.7-6.5H68.3L66.5,6.5,64.7,2.1H63.8L66,7.6l-0.4.8a0.8,0.8,0,0,1-.8.6H64.4V9.6ZM57.7,7.5V6.7a2.3,2.3,0,0,0,1.8.9C61,7.6,62,6.6,62,4.8s-1-2.9-2.4-2.9a2.3,2.3,0,0,0-1.8,1V0H56.9V7.5h0.8Zm0-1.5V3.6a2,2,0,0,1,1.6-.9,1.8,1.8,0,0,1,1.8,2.1,1.8,1.8,0,0,1-1.8,2.1A2,2,0,0,1,57.7,6Zm-10.4.9a1.8,1.8,0,0,1-1.7-2.1,1.8,1.8,0,0,1,1.7-2.1,2,2,0,0,1,1.6.9V6A2,2,0,0,1,47.3,6.9Zm2.5,0.6V0H48.9V2.9a2.3,2.3,0,0,0-1.8-1c-1.4,0-2.4,1.1-2.4,2.9s1,2.8,2.4,2.8a2.3,2.3,0,0,0,1.8-.9V7.5h0.8ZM41.6,4.4H38a1.8,1.8,0,0,1,1.8-1.8A1.8,1.8,0,0,1,41.6,4.4ZM39.9,7.6A3,3,0,0,0,42,6.8l-0.4-.6a2.4,2.4,0,0,1-1.7.7,1.9,1.9,0,0,1-2-1.9h4.4V4.8a2.6,2.6,0,0,0-2.6-2.9,2.7,2.7,0,0,0-2.7,2.8A2.7,2.7,0,0,0,39.9,7.6Zm-6.6-.1V3.7a2,2,0,0,1,1.5-.9h0.3V2a2.3,2.3,0,0,0-1.8,1V2.1H32.4V7.5h0.8ZM29,4.4H25.4a1.8,1.8,0,0,1,1.8-1.8A1.8,1.8,0,0,1,29,4.4ZM27.3,7.6a3,3,0,0,0,2.2-.8l-0.4-.6a2.4,2.4,0,0,1-1.7.7,1.9,1.9,0,0,1-2-1.9h4.4V4.8a2.6,2.6,0,0,0-2.6-2.9,2.7,2.7,0,0,0-2.7,2.8A2.7,2.7,0,0,0,27.3,7.6Zm-6.4-.1,1.7-5.4H21.8L20.4,6.4,19,2.1H18.3L16.9,6.4,15.5,2.1H14.7l1.7,5.4h0.8l1.4-4.4,1.4,4.4h0.8ZM10,6.9A1.9,1.9,0,0,1,8.2,4.8,1.9,1.9,0,0,1,10,2.7a1.9,1.9,0,0,1,1.8,2.1A1.9,1.9,0,0,1,10,6.9Zm0,0.8a2.7,2.7,0,0,0,2.7-2.9,2.7,2.7,0,1,0-5.4,0A2.7,2.7,0,0,0,10,7.6ZM2.9,3.7h-2V0.8h2A1.4,1.4,0,0,1,4.4,2.3,1.4,1.4,0,0,1,2.9,3.7Zm-2,3.8v-3H3A2.2,2.2,0,0,0,5.4,2.3,2.2,2.2,0,0,0,3,0H0V7.5H0.9Z" fill="#515251" fillRule="evenodd"/>
          <g>
            <path d="M23.8,20.5h-6a4.1,4.1,0,0,0,.7,2.7,2.7,2.7,0,0,0,2.4,1.2,3.4,3.4,0,0,0,2.6-1.4l0.6,0.5a4.2,4.2,0,0,1-3.5,1.8,4.3,4.3,0,0,1-3.2-1.3,4.4,4.4,0,0,1-1.3-3.2,4.4,4.4,0,0,1,1.2-3.1,4,4,0,0,1,3-1.2,3.3,3.3,0,0,1,2.7,1.2,4.3,4.3,0,0,1,.9,3m-6-.8h4.1a2.9,2.9,0,0,0-.4-1.8,1.7,1.7,0,0,0-1.5-.8,1.9,1.9,0,0,0-1.6.8,3.3,3.3,0,0,0-.6,1.8" fill="#515251"/>
            <path d="M28.3,23l1.4-3.4a6.6,6.6,0,0,0,.6-1.7,0.6,0.6,0,0,0-.5-0.5,0.5,0.5,0,0,1-.5-0.5V16.6h3.3v0.3a0.3,0.3,0,0,1-.2.3l-0.5.2a2.5,2.5,0,0,0-.7,1.1l-2.9,6.6h-1l-3-6.7a2.1,2.1,0,0,0-.7-0.9H23.3a0.3,0.3,0,0,1-.2-0.4V16.6h4v0.3a0.5,0.5,0,0,1-.5.5,0.5,0.5,0,0,0-.5.4l0.2,0.7Z" fill="#515251"/>
            <path d="M39.7,20.5h-6a4.1,4.1,0,0,0,.7,2.7,2.7,2.7,0,0,0,2.4,1.2,3.4,3.4,0,0,0,2.6-1.4l0.6,0.5a4.2,4.2,0,0,1-3.5,1.8,4.3,4.3,0,0,1-3.2-1.3,4.4,4.4,0,0,1-1.3-3.2A4.4,4.4,0,0,1,33,17.6a4,4,0,0,1,3-1.2,3.3,3.3,0,0,1,2.7,1.2,4.3,4.3,0,0,1,.9,3m-6-.8h4.1a2.9,2.9,0,0,0-.4-1.8,1.7,1.7,0,0,0-1.5-.8,1.9,1.9,0,0,0-1.6.8,3.3,3.3,0,0,0-.6,1.8" fill="#515251"/>
            <path d="M42.5,16.5v2.6a5.1,5.1,0,0,1,.8-1.8,2,2,0,0,1,1.6-1,1.3,1.3,0,0,1,.8.3,0.9,0.9,0,0,1,.3.7,0.8,0.8,0,0,1-.3.6l-0.7.3H44.2q-0.6,0-1.1,1.5a7,7,0,0,0-.5,2.4v1.6a0.7,0.7,0,0,0,.3.7h0.5a0.3,0.3,0,0,1,.3.4V25H39.9V24.7a0.3,0.3,0,0,1,.3-0.4h0.5a0.7,0.7,0,0,0,.3-0.7V18.1a0.7,0.7,0,0,0-.3-0.7H40.1a0.3,0.3,0,0,1-.3-0.4V16.6h2.7Z" fill="#515251"/>
            <path d="M49,24.5V27a0.7,0.7,0,0,0,.3.7h0.5a0.3,0.3,0,0,1,.3.4v0.3H46.3V28.2a0.3,0.3,0,0,1,.2-0.4H47a0.8,0.8,0,0,0,.2-0.7V18.1a0.7,0.7,0,0,0-.4-0.8,0.4,0.4,0,0,1-.4-0.4V16.6h2.4v0.8a4,4,0,0,1,2.6-1,3.7,3.7,0,0,1,2.9,1.3,4.6,4.6,0,0,1,1.1,3.1,4.7,4.7,0,0,1-1.1,3.2,3.7,3.7,0,0,1-3,1.3,4.4,4.4,0,0,1-2.3-.7m0-6.7v5.9a2.7,2.7,0,0,0,1.9.7,2.3,2.3,0,0,0,2.1-1.2,5.2,5.2,0,0,0,.5-2.5,4.7,4.7,0,0,0-.6-2.4,2.3,2.3,0,0,0-2.1-1.1,2.7,2.7,0,0,0-1.9.7" fill="#515251"/>
            <path d="M58.5,11.5V23.5a0.8,0.8,0,0,0,.2.7h0.4a0.3,0.3,0,0,1,.2.4V25H55.9V24.7a0.3,0.3,0,0,1,.2-0.4h0.5a0.8,0.8,0,0,0,.2-0.7V13.1a0.8,0.8,0,0,0-.2-0.7H56.2a0.3,0.3,0,0,1-.2-0.4V11.6h2.6Z" fill="#515251"/>
            <path d="M64.9,19.7q0-2.7-1.7-2.7a0.7,0.7,0,0,0-.7.8,0.9,0.9,0,0,1-1,.9l-0.6-.2a0.8,0.8,0,0,1-.3-0.6,1.3,1.3,0,0,1,1-1.2,4.2,4.2,0,0,1,1.6-.3,3.2,3.2,0,0,1,3,1.5,3.8,3.8,0,0,1,.4,1.9v2.7q0,1.9.8,1.9l0.5-.2H68v0.3a0.7,0.7,0,0,1-.4.5l-0.9.3A1.6,1.6,0,0,1,65.1,24a3.4,3.4,0,0,1-2.5,1.2,3.3,3.3,0,0,1-2-.6,2.2,2.2,0,0,1-.9-1.8q0-2.6,5.2-3.1M65,22V20.3a5.5,5.5,0,0,0-2.2.6,2,2,0,0,0-1.2,1.9,1.7,1.7,0,0,0,.4,1.1,1.3,1.3,0,0,0,1.1.4,1.9,1.9,0,0,0,1.2-.5A2.4,2.4,0,0,0,65,22" fill="#515251"/>
            <path d="M68.6,17V16.6h2.4v0.9a3.9,3.9,0,0,1,2.7-1.1q2.8,0,2.8,2.4v4.8a0.8,0.8,0,0,0,.2.7h0.4a0.3,0.3,0,0,1,.2.4V25H73.9V24.7a0.3,0.3,0,0,1,.2-0.4h0.5a0.8,0.8,0,0,0,.2-0.7V19.2a2.8,2.8,0,0,0-.3-1.4,1.6,1.6,0,0,0-1.5-.6l-1.1.3a2,2,0,0,0-.9.7v5.3a0.8,0.8,0,0,0,.2.7h0.5a0.3,0.3,0,0,1,.2.4V25H68.6V24.7a0.5,0.5,0,0,1,.4-0.4,0.7,0.7,0,0,0,.4-0.8V18.1a0.8,0.8,0,0,0-.2-0.7H68.9a0.3,0.3,0,0,1-.2-0.3" fill="#515251"/>
            <path d="M80.7,25.2a5.6,5.6,0,0,1-2-.3,1.5,1.5,0,0,1-1.3-1.3,0.8,0.8,0,0,1,.3-0.7,1.1,1.1,0,0,1,.7-0.3l0.7,0.2a0.7,0.7,0,0,1,.3.6v0.5q0,0.6,1.2.6a2.5,2.5,0,0,0,1.3-.3,1.2,1.2,0,0,0,.7-1.1,1.6,1.6,0,0,0-1.2-1.4l-2.3-.9a2,2,0,0,1-1.2-1.9A2.2,2.2,0,0,1,79,17a4.2,4.2,0,0,1,2.3-.6,4.2,4.2,0,0,1,1.6.3,1.3,1.3,0,0,1,1,1.2,0.7,0.7,0,0,1-.3.6l-0.7.2-0.6-.2a0.7,0.7,0,0,1-.3-0.6V17.5q0-.5-0.9-0.5a1.8,1.8,0,0,0-1,.3,1.1,1.1,0,0,0-.5.9,1.6,1.6,0,0,0,1.3,1.3l2.3,0.9a2.1,2.1,0,0,1,1.3,2,2.4,2.4,0,0,1-1.2,2.1,4.3,4.3,0,0,1-2.4.7" fill="#515251"/>
            <path d="M12.8,20.7a6.4,6.4,0,1,1-6.4-6.4,6.4,6.4,0,0,1,6.4,6.4m-8.7,0a1.4,1.4,0,1,0-1.4,1.4,1.4,1.4,0,0,0,1.4-1.4m3.7,0a1.4,1.4,0,1,0-1.4,1.4,1.4,1.4,0,0,0,1.4-1.4m3.7,0a1.4,1.4,0,1,0-1.4,1.4,1.4,1.4,0,0,0,1.4-1.4" fill="#515251"/>
            <g>
              <path d="M84.2,16.6V15.5H83.8V15.2h1.1v0.2H84.5v1.1H84.2Z" fill="#515251"/>
              <path d="M85,16.6V15.2h0.4l0.2,0.9,0.2-.9h0.4v1.3H86.1v-1l-0.3,1H85.6l-0.3-1v1H85Z" fill="#515251"/>
            </g>
          </g>
        </svg>
    </div> )
  }
}
