import InputBase from './InputBase'
import Input from './Input'
import Wrapper from './Wrapper'

export default class Checkbox extends InputBase {
  label() { return this.props.children ? this.props.children : this.props.label }

  render() {
    if (this.props.wrap) {
      return (
        <Wrapper {...this.props} errors={this.errors()} label=' '>
          <CheckElement {...this.props} />
          {this.label()}
        </Wrapper>
      )
    } else {
      return <CheckElement {...this.props} />
    }
  }
}

Checkbox.defaultProps = {wrap: true}


// TODO: `defaultChecked` should use `props.defaultValue` not `props.value`:
const CheckElement = props => <Input disabled={props.disabled} type='checkbox' wrap={false} name={props.name} defaultChecked={props.value === true} onChange={props.onChange} />
