import React from 'react'
import InputBase from './InputBase'
import classnames from 'classnames'

export default class Label extends InputBase {
  classNames() {
    return this.props.className ? classnames('form-label', this.props.className.split(' ')) : 'form-label'
  }

  render() {
    if (!this.props.label) return <span>{this.props.children}</span>

    return (
      <label className={this.classNames()} htmlFor={this.name()}>
        <span className='form-label-text'>{this.props.label}</span>
        {this.props.children}
      </label>
    )
  }
}

/*
 * Something that might not be obvious here.
 * This is setup to apply a `for` attribute to link to
 * a possibly nested input value. It can be explicilty passed as 'name'
 * or, if 'label' is passed down in a spread operator, it'll get converted
 * to an an underscord value
 */
