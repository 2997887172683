import classnames from 'classnames'

import CompoundFormComponent from './CompoundFormComponent'
import Input from './Input'
import Wrapper from './Wrapper'
import Validators from './Validators'
export default class Name extends CompoundFormComponent {
  render() {
    return (
      <Wrapper {...this.props} label={this.props.label ? this.props.label : 'Your name'} name='name' errors={this.errors('first_name')}>
        <Input name='first_name' type='text' className={classnames('first-name', this.props.inputsClassname)} validator={Validators.required} placeholder='First' defaultValue={this.props.defaultValue.first_name} wrap={false} />
        <Input name='last_name' type='text' className={classnames('last-name', this.props.inputsClassname)} validator={Validators.required} placeholder='Last' defaultValue={this.props.defaultValue.last_name} wrap={false} />
      </Wrapper>
    )
  }
}

Name.defaultProps = {
  validator: (name, data) => {
    const constraints = {
      first_name: {
        presence: {message: '^Please add your first name.'}
      },
      last_name: {
        presence: {message: '^Please add your last name.'}
      }
    }

    return Validators.template('name', data, constraints)
  },
  defaultValue: {first_name: '', last_name: ''},
  inputsClassname: ''
}
