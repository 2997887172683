import {List, fromJS} from 'immutable'

// taken from apiHelpers, but needed some tweeking
export const cleanResponse = (apiResource, fallback = List()) => {
  if (apiResource) {
    const resource = apiResource.data
    const immutableResponse = fromJS(resource)

    // Ensure everything has the proper ID if it's a list of items (e.g., an #index request):
    if (List.isList(immutableResponse))
      return immutableResponse.map(datum => datum.get('attributes').set('id', datum.get('id')))

    // Otherwise let the consumer of the data handle it:
    return immutableResponse
  } else {
    return fallback
  }
}

/**
 * The redux-json-api library adds `isInvalidating` if any existing resource is in the process of being updated. This method
 * takes a resource list and checks if any are updating, by checking if `isInvalidating` is present on at least one.
 *
 * @param {object} resourceList directly from `state.api`
 *
 * @returns {Boolean} whether any resource inside the resource list is updating
 */
export const isUpdatingResourceList = (resourceList = {data: []}) => (
  resourceList.data.some(resource => resource.isInvalidating)
)
