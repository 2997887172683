import {List} from 'immutable'

export const domainEmailRegex = domainName => new RegExp(`^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(${domainName})$`, 'g')

export const deputizableDomainEmails = ({contact, organization}) => {
  const emails = contact.get('emails', List())

  if (organization.get('block-deputization'))
    return emails.filter(email => !domainEmailRegex(organization.get('domain-names')).test(email))
  else
    return emails
}
