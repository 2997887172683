import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import modalWrapper, {MODAL_CONTEXT_TYPES} from '../../ModalWrapper'

import routerUtils from '../../../lib/routerUtils'

const ModalLauncher = ({launcher, showModal}) => routerUtils.childrenWithProps(launcher, {onClick: showModal})

const modalFactory = (ModalComponent, displayName, topLevelClassName) => {
  // Note: The props destructuring is done to prevent passage of possibly-confusing launcher and className to the lower-level modal. --BLR
  const NewModalType = ({className, launcher, ...modalComponentProps}, context) => (
    <div className={classnames('core modal', topLevelClassName, className)}>{/* topLevelClassName is used during class creation, while className is used on instantiation. --BLR */}
      {launcher && <ModalLauncher launcher={launcher} showModal={context.showModal} />}{/* Auto-opening modals don't need to pass a launcher. --BLR */}
      <ModalComponent topLevelClassName={topLevelClassName} {...modalComponentProps} />
    </div>
  )

  NewModalType.contextTypes = MODAL_CONTEXT_TYPES

  NewModalType.displayName = displayName

  NewModalType.propTypes = {launcher: PropTypes.node}

  return modalWrapper(NewModalType)
}

export default modalFactory
