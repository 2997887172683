import {combineReducers} from 'redux'
import {reducer as api} from 'redux-json-api'
import keyFieldsReducer from './dashboard/src/components/corp_max/redux/key_fields_tool/reducer'
import kitsReducer from './dashboard/src/components/corp_max/reducers/kitsReducer'
import elementsReducer from './dashboard/src/components/corp_max/reducers/elementsReducer'
import itemsReducer from './dashboard/src/components/corp_max/reducers/itemsReducer'
import categoriesReducer from './dashboard/src/components/corp_max/reducers/categoriesReducer'
import listMappingsReducer from './dashboard/src/components/corp_max/reducers/listMappingsReducer'
import keyFieldMappingsReducer from './dashboard/src/components/corp_max/reducers/keyFieldMappingsReducer'
import viewsReducer from './dashboard/src/components/corp_max/reducers/viewsReducer'
import ownersReducer from './dashboard/src/components/corp_max/reducers/ownersReducer'
import gapFillerAssessmentReducer from './shared_components/next_best_actions/gap_fillers/gap_filler_assessments/reducer/gapFillerAssessmentReducer'
import plansReducer from './dashboard/src/components/corp_max/reducers/plansReducer'

const rootReducer = combineReducers({
  api,
  keyFieldsToolData: keyFieldsReducer,
  kits: kitsReducer,
  elements: elementsReducer,
  items: itemsReducer,
  categories: categoriesReducer,
  listMappings: listMappingsReducer,
  keyFieldMappings: keyFieldMappingsReducer,
  views: viewsReducer,
  owners: ownersReducer,
  gapFillerAssessment: gapFillerAssessmentReducer,
  plans: plansReducer
})

export default rootReducer
