import Input from './Input'
import Select from './Select'
import Validators from './Validators'
import Wrapper from './Wrapper'
import CompoundFormComponent from './CompoundFormComponent'

var months = [
  {key: '1', value: 'January'},
  {key: '2', value: 'February'},
  {key: '3', value: 'March'},
  {key: '4', value: 'April'},
  {key: '5', value: 'May'},
  {key: '6', value: 'June'},
  {key: '7', value: 'July'},
  {key: '8', value: 'August'},
  {key: '9', value: 'September'},
  {key: '10', value: 'October'},
  {key: '11', value: 'November'},
  {key: '12', value: 'December'}]

export default class DateInput extends CompoundFormComponent {
  constructor(props) {
    super(props)
    this.setInitialState(props)
    this.state = {formData: {}}
  }


  formValue() {
    if ([this.state.month, this.state.day, this.state.year].every(element => element)) {
      const newDate = new Date(`${this.state.month}/${this.state.day}/${this.state.year}`)
      if ((newDate.getDate() == this.state.day) && ((newDate.getMonth() + 1) == this.state.month) && (newDate.getFullYear() == this.state.year))
        return newDate
      return 'Invalid Date'
    }
    return null
  }

  getChildContext() {
    return {setFormValue: this.setFormValue.bind(this), errors: this.context.errors}
  }

  setFormValue(key, value) {
    const formData = this.state.formData || {}
    formData[key] = value
    this.setState(formData, () => {
      this.context.setFormValue(this.props.name, this.formValue())
    })
  }

  render() {
    const defaultValue = this.props.defaultValue
    return (
      <Wrapper className='form-date' label={this.props.label} errors={this.errors()}>
        <Select placeholder='Month' className='month' name='month' wrap={false} list={months} defaultValue={defaultValue ? defaultValue.month : null} />
        <Input placeholder='Day' className='day' name='day' wrap={false} defaultValue={defaultValue ? defaultValue.day : null} />
        <Input autoComplete='off' placeholder='Year' className='year' name='year' wrap={false} defaultValue={defaultValue ? defaultValue.year : null} />
      </Wrapper>
    )
  }
}

DateInput.defaultProps = {
  validator(name, data) {
    const constraints = {}
    constraints[name] = {datetime: true, presence: true}
    return Validators.template(name, data, constraints)
  },
  defaultValue: null
}
