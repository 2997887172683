import {Map} from 'immutable'

import {cleanResponse} from '../redux/apiHelper'

import {RESHAPE_KEY_FIELD_MAPPINGS} from '../actions/corpMaxActionTypes'

const keyFieldMappingsReducer = (state = Map({}), action) => {
  switch (action.type) {
    case RESHAPE_KEY_FIELD_MAPPINGS:
      return cleanResponse(action.payload).first().get('data')
    default:
      return state
  }
}

export default keyFieldMappingsReducer
