import PropTypes from 'prop-types'

import InputBase from './InputBase'
import Wrapper from './Wrapper'

export default class Select extends InputBase {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.allowNewValueProp && (newProps.value !== this.props.value))
      this.onFormElementChange({target: {value: newProps.value}})
  }
  onChange(event) {
    if (event.target.value === this.props.placeholder) // Prevent placeholder as formValue
      event.target.value = null

    this.onFormElementChange(event)
  }

  render() {
    /* eslint-disable-next-line no-unused-vars */
    const {allowNewValueProp, wrap, ...selectProps} = this.props
    const Element = <SelectElement {...selectProps} onChange={this.onChange.bind(this)} value={this.state ? this.state.formValue : null} />

    if (this.props.wrap)
      return <Wrapper {...this.props} errors={this.errors()}>{Element}</Wrapper>

    return Element
  }
}

Select.defaultProps = {allowNewValueProp: false, wrap: true}

const SelectElement = props => (<span className='dropdown'>
  <select {...props}>
    <option name={props.name}>{props.placeholder}</option>
    {props.list.map(item => <option key={item.key} value={item.key}>{item.value}</option>)}
  </select>
</span>
)

SelectElement.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.node,
      value: PropTypes.node
    }).isRequired
  ),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
}
