/**
  Scrolls the top of our pages and modals. Only do anything if not running tests
  to avoid potentially messing up `test-watch` behavior
*/

export const scrollToTopOfModal = () => {
  if (!__TEST__) {
    const modal = document.getElementsByClassName('base-modal-contents-wrapper')[0]

    if (modal)
      modal.scrollTop = 0
  }
}

export const scrollToTopOfPage = () => {
  if (!__TEST__)
    document.body.scrollTop = document.documentElement.scrollTop = 0
}

/** Handles maintaining the position of the background content of a modal.
 * Needed to make this a closure pattern so that scrollTop position can be
 * properly tracked
 *
 * @param {String} wrapperClass The class name of the page to be locked
*/
export const handleModalWrapperPosition = (wrapperClass = '.corp-max-controller') => {
  let scrollTop = 0

  // Lock wrapper so that it maintains the position that it has
  // before opening the modal and prevent it from scrolling - Kay
  const lockWrapper = () => {
    scrollTop = window.pageYOffset

    if (scrollTop)
      $(wrapperClass).css({top: -(scrollTop), position: 'relative'})
  }

  // Unlock wrapper so that it makes it scrollable again. - Kay
  const unlockWrapper = () => {
    if (!__TEST__) {
      $(wrapperClass).css({top: '', position: ''})
      window.scrollTo(0, scrollTop)
    }
  }

  const updateHtmlBodyCss = cssValue => {
    ['html', 'body'].forEach(tagName => $(tagName).css({...cssValue}))
  }

  // Keeps the html and body tag in place so that the background scroll can be fully locked. - Kay
  const lockPage = () =>
    updateHtmlBodyCss({overflow: 'hidden', position: 'relative'})

  // Restores the html and body tag after locking. - Kay
  const unlockPage = () => updateHtmlBodyCss({overflow: '', position: ''})

  return {
    lockPage,
    lockWrapper,
    unlockPage,
    unlockWrapper
  }
}

export const scrollModalTopIntoView = () => {
  if (!__TEST__) {
    const modal = document.getElementsByClassName('base-modal-contents')[0]

    if (modal && modal.hasChildNodes())
      modal.firstChild.scrollIntoView(false)
  }
}

export const scrollComponentToView = className => {
  if (!__TEST__ && document) {
    const component = document.getElementsByClassName(className)[0]

    if (component) component.scrollIntoView({behavior: 'smooth'})
  }
}

export const setModalOpenClassOnBody = isModalOpen => {
  $('html').toggleClass('core-modal-open', isModalOpen)
}
