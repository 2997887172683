import LegacyActionFactory from '../shared_components/LegacyActionFactory'
import bootUtils from '../lib/bootUtils'

const ConfigActions = new LegacyActionFactory([
  'GOT_CONFIG'
])

// There is a request that is very similar to that made by the action below used in the bootConfig method in bootUtils. That method is
// intended to be called during the bootstrapping of the app, while this is only meant to be fired *after* the app has fully booted. --BLR
ConfigActions.fetchConfig = () => {
  ConfigActions.fireApi(
    'get',
    bootUtils.configApiPath(),
    null,
    {
      JSONHead: 'config',
      successAction: ConfigActions.Types.GOT_CONFIG
    }
  )
}

export default ConfigActions
