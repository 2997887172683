import $ from 'jquery'
import classnames from 'classnames'

/** @class Button
 *
 * @deprecated Do not use this in new code. Use the buttons in
 *   +dashboard/src/components/blocks/Buttons.react.js+ instead.
 * @desc
 * Legacy component to handle common `button` functionality and styling.
 */

import {Component} from 'react'
import PropTypes from 'prop-types'

import buttonLoader from '../pro/assets/images/button_loader.gif'

import './spinner.scss'


export default class Button extends Component {
  constructor() {
    super()

    this.state = {
      spinnerStyle: {}
    }

    this.onClick = this.onClick.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loading || !nextProps.loading)
      return

    const button = $(this.refs.button)

    this.setState({
      spinnerStyle: {
        height: button.outerHeight(),
        marginBottom: button.css('margin-bottom'),
        marginLeft: button.css('margin-left'),
        marginRight: button.css('margin-right'),
        marginTop: button.css('margin-top'),
        width: button.outerWidth()
      }
    })
  }

  onClick(event) {
    if (event)
      event.preventDefault()

    if (this.props.onClick)
      this.props.onClick()
  }

  onMouseDown(event) {
    if (event)
      event.preventDefault()

    if (this.props.onMouseDown)
      this.props.onMouseDown()
  }

  buttonProps() {
    return {
      children: this.props.children,
      className: classnames('btn', this.props.className, {disabled: this.props.disabled}),
      disabled: this.props.disabled,
      onClick: this.onClick,
      onMouseDown: this.onMouseDown,
      ref: 'button',
      tabIndex: this.props.tabIndex
    }
  }

  render() {
    return (
      <div className='spinner-holder'>
        {
          this.props.loading ?
            (
              <div className='spinner' style={this.state.spinnerStyle}>
                <img alt='Button in loaing state' src={buttonLoader} />
              </div>
            ) :
            <button {...this.buttonProps()} />
        }
      </div>
    )
  }
}

Button.defaultProps = {
  loading: false
}

Button.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
