import PropTypes from 'prop-types'
import classnames from 'classnames'

import Logo from '../Logo'

import {cobrandingPrefixText} from '../../lib/cobrandingTools'

import './everplansBrandedCobranding.scss'

const EverplansBrandedCobranding = ({cobranding, leftAligned}) => (
  <div className={classnames('everplans-branded-cobranding', {'left-aligned': leftAligned})} >
    <Logo />
    <div className='cobranding-text'>
      {cobrandingPrefixText(cobranding)}
    </div>
  </div>
)

EverplansBrandedCobranding.propTypes = {
  cobranding: PropTypes.shape({
    cobranding_prefix: PropTypes.string,
    cobrandable: PropTypes.shape({
      name: PropTypes.string
    }).isRequired
  }).isRequired,
  leftAligned: PropTypes.bool
}

export default EverplansBrandedCobranding
