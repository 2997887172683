import {Map} from 'immutable'

import {GAP_FILLER_ASSESSMENT_STEP} from '../actions/gapFillerActionTypes'

const gapFillerAssessmentReducer = (state = Map({step: 0}), action) => {
  switch (action.type) {
    case GAP_FILLER_ASSESSMENT_STEP:
      return state.set('step', action.payload)
    default:
      return state
  }
}

export default gapFillerAssessmentReducer
