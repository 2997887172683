/** @class ApplicationFallback
 *
 * @desc
 * This is a modified version of the code used on the 404 page that is shown when an uncaught
 * error bubbles up to the highest level of the React app (at least to the routes level). I could
 * not re-use the 404 code directly as the React app is already nested under the Jade/Pug templates
 * handled by the Express routing and that would have created invalid DOM nesting among many other
 * problems.
 */

import {PureComponent} from 'react'

import HeaderController from '../header/Header'
import OpenHelpTicketLink from './OpenHelpTicketLink'

import routerUtils from '../../lib/routerUtils'

import errorPageIcon from '../../consumer/src/assets/images/error-page-icon.png'

import './applicationFallback.scss'


export default class ApplicationFallback extends PureComponent {
  /** Reloads the current page. `window.location.reload` cannot be invoked inline and must be called here. */
  reload() { window.location.reload() }

  render() {
    return (
      <div className='application-fallback'>
        <HeaderController flavor='transactional' />
        <main>
          <h2>We apologize&mdash;an error has occurred.</h2>
          <div className='box-with-image'>
            <img className='box-image' src={errorPageIcon} alt='Unknown application error' />
            <div>
              <h3>Unknown application error</h3>
              <p>
                We have logged this error and reported it to our engineering team.
              </p>
            </div>
          </div>
          <div className='suggestions'>
            <h4>Suggestions for troubleshooting:</h4>
            <div className='suggestion'>
              <div className='number-circle'>
                <span>1</span>
              </div>
              <p>
                Some errors are temporary and can be resolved by trying again:
                either <a onClick={this.reload}>reload this page</a> or <a onClick={routerUtils.goBack}>go back</a> to
                the page you were just viewing and repeat your previous action.
              </p>
            </div>
            <div className='suggestion'>
              <div className='number-circle'>
                <span>2</span>
              </div>
              <p>
                If the error persists and you'd like individual help, please <OpenHelpTicketLink /> in our Help Center.
              </p>
            </div>
          </div>
          <footer className='go-to-links'>
            <h4>Go to:</h4>
            <ul>
              <li><a href={WEBAPPS_HOST}>My Everplans Dashboard</a></li>
              <li><a href='https://help.everplans.com'>Help Center</a></li>
              <li><a onClick={routerUtils.goBack}>Back to previous page</a></li>
            </ul>
          </footer>
        </main>
      </div>
    )
  }
}
