import {useState, useCallback} from 'react'

import AnnouncementTooltip from './AnnouncementTooltip'

import './pronounsTooltip.scss'

const PronounsTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false)

  const displayTooltip = useCallback(() => setShowTooltip(true), [])

  const hideTooltip = useCallback(() => setShowTooltip(false), [])

  return (
    <div className='pronouns-tooltip'>
      <a onClick={displayTooltip}>Why do we ask for this information?</a>
      <AnnouncementTooltip showTooltip={showTooltip} hideTooltip={hideTooltip}>
        We ask this information to use the most respectful language when
        communicating about your Everplan’s activity to your deputies.
      </AnnouncementTooltip>
    </div>
  )
}

export default PronounsTooltip
