import classnames from 'classnames'

import './texts.scss'


export const Text = props => <div {...props} className={classnames('core', 'text', props.className)} />

export const TextGutterSmall = props => <Text {...props} className={classnames('gutter-small', props.className)} />

export const TextGutterMedium = props => <Text {...props} className={classnames('gutter-medium', props.className)} />

export const TextGutterLarge = props => <Text {...props} className={classnames('gutter-large', props.className)} />

export const TextGutterExtraLarge = props => <Text {...props} className={classnames('gutter-extra-large', props.className)} />
