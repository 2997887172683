import {ToastContainer, toast, Slide} from 'react-toastify'
import {createContext, useContext} from 'react'
import 'react-toastify/dist/ReactToastify.min.css' // Without this the notifications don't show

import {objectsAreEqual} from '../../lib/tools'

import scoreNotificationIcon from '../../dashboard/assets/progress-meter-icon.svg'

import './notificationContext.scss'

export const NotificationContext = createContext()
export const useNotificationContext = () => useContext(NotificationContext)

export const NotificationContextProvider = ({children}) => {
  const notify = ({shouldNotify, message, options}) => {
    if (shouldNotify) toast(message, options)
  }

  const createNotifier = shouldNotify => ({
    // Notifications that disappear in four seconds:
    shortError: message => notify({shouldNotify, message, options: {type: 'error'}}),
    shortInfo: message => notify({shouldNotify, message, options: {type: 'info'}}),
    shortSuccess: message => notify({shouldNotify, message, options: {type: 'success'}}),
    shortWarning: message => notify({shouldNotify, message, options: {type: 'warning'}}),

    // Notifications that disappear in 8 seconds:
    longError: message => notify({shouldNotify, message, options: {type: 'error', autoClose: 8000}}),
    longInfo: message => notify({shouldNotify, message, options: {type: 'info', autoClose: 8000}}),
    longSuccess: message => notify({shouldNotify, message, options: {type: 'success', autoClose: 8000}}),
    longWarning: message => notify({shouldNotify, message, options: {type: 'warning', autoClose: 8000}}),

    // Notifications that the user must dismiss:
    error: message => notify({shouldNotify, message, options: {type: 'error', autoClose: false}}),
    info: message => notify({shouldNotify, message, options: {type: 'info', autoClose: false}}),
    success: message => notify({shouldNotify, message, options: {type: 'success', autoClose: false}}),
    warning: message => notify({shouldNotify, message, options: {type: 'warning', autoClose: false}}),

    // Score Ledger Event Notifications:
    scoreSuccess: (message, delay) => notify({
      shouldNotify, message, options: {
        icon: () => <img alt='score-notification-icon' className='score-notification-icon' src={scoreNotificationIcon} />,
        type: 'success',
        delay
      }
    })
  })

  const notificationMethods = {
    alwaysNotify: createNotifier(true),
    dismissToastNotification: () => toast.dismiss(),
    isDifferent(newData, oldData) { return createNotifier(!objectsAreEqual(newData, oldData)) }
  }

  return (
    <NotificationContext.Provider value={notificationMethods}>
      <ToastContainer
        className='notification-context'
        transition={Slide}
        position='top-right'
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme='light'
      />
      {children}
    </NotificationContext.Provider>
  )
}


