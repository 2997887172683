import {sortByKey} from './tools'
import {arrayToHumanizedList} from './textTools'

export const lastNameFirst = displayName => `${displayName.last_name}, ${displayName.first_name}`
export const sortByFirstName = users => sortByKey(users, 'first_name')
export const sortByLastName = users => sortByKey(users, 'last_name')

// This makes sure that undefined is not returned if first or last name do not exist
export const firstName = user => user.get('first-name', '')

export const lastName = user => user.get('last-name', '')

export const sameLastName = namesArray => (
  namesArray.filter(name => name.get('last-name') === namesArray.getIn([0, 'last-name'])).size > 1
)

/** Helper method to return formatted names for individuals and households as string:
 * if it is a single object returns first and last name with a space in between
 * if all the names have the same last name then first names are joined with an and with the last name at the end
 * if the names do not have the same last name then each first and last name is returned with an and in between
 *
 * @param {List} namesList A list of name objects
 * @return {String} of last and first name depending on what information is provided
*/
export const humanizedFullNames = namesList => {
  let names
  if (namesList.size === 1)
    names = `${firstName(namesList.get(0))} ${lastName(namesList.get(0))}`
  else if (sameLastName(namesList))
    names = `${namesList.map(user => firstName(user)).join(' and ')} ${lastName(namesList.get(0))}`
  else
    names = namesList.map(user => `${firstName(user)} ${lastName(user)}`).join(' and ')

  return names.trim() // So that string with an empty space is not being returned
}

export const humanizedFullName = name => `${firstName(name)} ${lastName(name)}`.trim()

export const humanizedFirstNamesWithConjunction = (users, conjunction) => arrayToHumanizedList(users.map(user => firstName(user)).toArray(), conjunction)

export const humanizedFullNamesWithConjunction = (users, conjunction) => arrayToHumanizedList(users.map(user => humanizedFullName(user)).toArray(), conjunction)

export const userInitials = user => `${firstName(user).charAt(0)}${lastName(user).charAt(0)}`
