import {List} from 'immutable'

import {cleanResponse} from '../redux/apiHelper'

import {RESHAPE_ITEMS} from '../actions/corpMaxActionTypes'

const itemsReducer = (state = List(), action) => {
  switch (action.type) {
    case RESHAPE_ITEMS:
      return cleanResponse(action.payload)
    default:
      return state
  }
}

export default itemsReducer
