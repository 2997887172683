import fetch from 'cross-fetch'
import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'

import extra_storage from '../lib/extra_storage'

const configuration = () => {
  let config = {uri: `${TRANSFORMER_HOST}/graphql`}

  // In some test envrionment, javascript fetch object is unavailable. This makes sure that cross-fetch is able to handle such environment
  if (__TEST__)
    config = {...config, fetch}

  return config
}

const httpLink = createHttpLink(configuration())

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = extra_storage.getItem('authToken')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Token token=${token}` : '',
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    possibleTypes: {
      Value: ["StringValue", "StringArrayValue", "DateValue", "AddressValue", "ContactValue", "FileArrayValue"]
    },
  })
})

export default client