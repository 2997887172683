export const RESHAPE_KITS = 'reshape_kits'
export const RESHAPE_ELEMENTS = 'reshape_elements'
export const RESHAPE_ITEMS = 'reshape_items'
export const RESHAPE_CATEGORIES = 'reshape_categories'
export const RESHAPE_LIST_MAPPINGS = 'reshape_list_mappings'
export const RESHAPE_KEY_FIELD_MAPPINGS = 'reshape_key_field_mappings'
export const RESHAPE_VIEWS = 'reshape_views'
export const RESHAPE_OWNERS = 'reshape_owners'
export const RESHAPE_PREMIUM_ITEMS = 'reshape_premium_items'
export const RESHAPE_PLANS = 'reshape_plans'
