import React from 'react'
import {If, Else} from '../deprecated/IfFlag'
import InputBase from './InputBase'
import Wrapper from './Wrapper'
import Input from './Input'
import Select from './Select'
import Validators from './Validators'
import CountryData from '../../lib/country-data.json'
import {filterSanctionedCountries} from '../../lib/tools'

export default class ZipOrCountry extends InputBase {
  constructor(props) {
    super(props)

    const showZip = !(props.defaultValue && props.defaultValue.country_code)
    this.state = {
      label: this.label(showZip),
      showZip
    }

    this.toggleZip = this.toggleZip.bind(this)
  }
  label(showZip) { return showZip ? 'ZIP code' : 'Country' }
  toggleZip() {
    this.clearFormValue('zip')
    this.clearFormValue('country_code')
    var showZip = !this.state.showZip
    this.setState({showZip, label: this.label(showZip)})
  }
  countries() { return filterSanctionedCountries(CountryData).map(country => ({key: country.code.toLowerCase(), value: country.name})) }

  render() {
    const defaultValue = this.props.defaultValue || {}
    return (
      <Wrapper errors={this.errors()} label={this.state.label} className={this.props.name}>
        <If flag={this.state.showZip}>
          <Input ref='zip' name='zip' wrap={false} className='zip' defaultValue={defaultValue.zip} />
          <a onClick={this.toggleZip} className='live-us'>I don't live in the United States</a>
        </If>
        <Else flag={this.state.showZip}>
          <Select wrap={false} ref='country' list={this.countries()} name='country_code' className='country' defaultValue={defaultValue.country_code} />
          <a onClick={this.toggleZip} className='live-us country-field'>I live in the United States</a>
        </Else>
      </Wrapper>
    )
  }
}

ZipOrCountry.defaultProps = {
  validator(name, data) {
    var pattern = /\d{5}(-\d{4})?/
    if (!data.zip && !data.country_code)
      return {name: 'zipOrCountry', errors: ['Please enter a ZIP or a country code.']}
    if (data.zip) {
      const trimData = {...data, zip: data.zip.trim()}
      return Validators.template(name, trimData, {zip: {presence: true, format: pattern}})
    } else {
      return Validators.template(name, data, {country_code: {presence: true}})
    }
  },
  defaultValue: null
}
