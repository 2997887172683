import $ from 'jquery'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {Component} from 'react'

import {setModalOpenClassOnBody} from '../../../lib/scrollTools'
import Closer from '../closer/Closer'
import {MODAL_CONTEXT_TYPES} from '../../ModalWrapper'
import {handleModalWrapperPosition} from '../../../lib/scrollTools'

export default class BaseModal extends Component {
  constructor(props) {
    super(props)

    this.wrapperPosition = handleModalWrapperPosition(props.wrapperContainerClass)
  }

  componentDidMount() {
    if (this.props.showModal || this.props.showModalOnMount) {
      this.wrapperPosition.lockWrapper()
      setModalOpenClassOnBody(true)

      // Actually open the modal for auto-opening modals:
      this.context.showModal && this.context.showModal() // Conditional is because modal may not always be used with modalWrapper. --BLR
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.showModal !== nextProps.showModal)
      setModalOpenClassOnBody(nextProps.showModal)
  }

  componentWillUnmount() {
    if (this.props.showModal) {
      setModalOpenClassOnBody(false)
      this.wrapperPosition.unlockWrapper()
    }
  }

  render() {
    const CloserComponent = this.props.closerComponent
    const contentsClassName = this.props.topLevelClassName ? `${this.props.topLevelClassName}-contents` : false

    return (
      <div className={classnames('core', 'base-modal', this.props.className)} ref='baseModal'>
        {this.props.showModal && <div className='base-modal-overlay' />}
        {
          this.props.showModal &&
          (
            <div className='base-modal-contents-wrapper' ref='baseModalContentsWrapper'>
              <div className={classnames('base-modal-contents', contentsClassName)}>
                <div className='base-modal-heading-closer'>
                  {
                    this.props.showCloser && (
                      <CloserComponent
                        fill={this.props.closerFillColor}
                        alt='Close modal'
                        className='base-modal-closer'
                        closer={this.context.modalClosed}
                      />
                    )
                  }
                </div>
                {this.props.children}
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

BaseModal.defaultProps = {
  closerComponent: Closer,
  showCloser: true,
  showModal: false,
  showModalOnMount: false,
  wrapperContainerClass: '.corp-max-controller'
}

BaseModal.propTypes = {
  closerComponent: PropTypes.func,
  closerFillColor: PropTypes.string,
  showCloser: PropTypes.bool,
  showModal: PropTypes.bool,
  showModalOnMount: PropTypes.bool,
  topLevelClassName: PropTypes.string,
  wrapperContainerClass: PropTypes.string
}

BaseModal.contextTypes = MODAL_CONTEXT_TYPES
