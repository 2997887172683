/*
  Warning: this component is only for use with API v1 and will (hopefully) be completely removed once we make the full
  transition to our JSONAPI-SPEC-compliant API v2. Please speak with Bart and/or Maneesh if you have any questions. --BLR
*/

import assign from 'object-assign'
import KeyMirror from 'keymirror'

import ActionPrototype from './ActionPrototype'
import AppDispatcher from '../lib/ep-dispatcher'
import * as tools from '../lib/tools'

export default class LegacyActionFactory {
  constructor(actionTypes) { // actionTypes is an array of strings which become action Types, via KeyMirror
    this.Types = KeyMirror(actionTypes.reduce((previous, keyName) => {
      previous[keyName] = null
      return previous
    }, {}))
    assign(this, ActionPrototype) // eventually we'll make all actions use this class and we don't need the prototype
  }

  /* -- builds the action method and calls fireApi for you example usage
    myActions.buildAction(
      'updateAssessment',
      'put',
      'quickplan/update',
      { successAction: myActions.Types.GOT_ASSESSMENT_QUESTION}
    )
  */
  buildAction(name, method, url, options) {
    this[name] = function(data) {
      if (method.toLowerCase() === 'delete' && !!data && (__DEV__ || __TEST__))
        console.log('Bad developer! Do not pass any data to a DELETE request!')

      this.fireApi(method, url, data, options)
    }
  }
  /* -- builds an action function that just dispatches a type with a passed data payload */
  buildActionDispatch(name, type) {
    this[name] = data => AppDispatcher.dispatch({data, actionType: type})
  }

  // HTTP-verb-specific builders:
  buildDelete(name, url, options) { this.buildAction(name, 'delete', url, options) }
  buildGet(name, url, options) { this.buildAction(name, 'get', url, options) }
  buildPost(name, url, options) { this.buildAction(name, 'post', url, options) }
  buildPut(name, url, options) { this.buildAction(name, 'put', url, options) }

  // Helper to auto-create actions used by BindToStores:
  buildBoundGet(resourceName, url, actionType, jsonHead = null) {
    this.buildAction(
      `fetch${tools.capitalize(resourceName)}`,
      'get',
      url,
      {
        JSONHead: jsonHead ? jsonHead : resourceName,
        successAction: actionType
      }
    )
  }
}
