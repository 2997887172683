import React from 'react'
import Container from '../lib/Container'
import ApplicationErrorBoundary from './error_boundaries/ApplicationErrorBoundary'

export const GlobalAnnouncementContext = React.createContext({})

const ApplicationContainer = props => {
  const configStore = Container.getStore('config')
  const configData = configStore ? Container.getStore('config').getState().data : {}
  const updatedConfig = {...configData, globalAnnouncementText: configData.global_announcement_text}

  return (
    <GlobalAnnouncementContext.Provider value={updatedConfig}>
      <ApplicationErrorBoundary>
        {props.children}
      </ApplicationErrorBoundary>
    </GlobalAnnouncementContext.Provider>
  )
}


export default ApplicationContainer
